import { ComponentProps } from 'react';

import { components } from 'react-select';

import { Icon } from 'components/ui/general';

export const ClearIndicator = (
  props: ComponentProps<typeof components.ClearIndicator>
) => {
  const { selectProps }: { [key: string]: any } = props;
  const { iconClearIndicator } = selectProps;

  if (iconClearIndicator)
    return (
      <components.ClearIndicator {...props}>
        <Icon name={iconClearIndicator} />
      </components.ClearIndicator>
    );

  return <components.ClearIndicator {...props} />;
};
