import { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { StellarTechLogo } from 'assets/icons';
import { Link } from 'components/tools';
import { Button, Icon, Spinner } from 'components/ui/general';
import { BackofficePaths } from 'consts/router';
import { useToast } from 'hooks';
import { logoutStart } from 'redux/auth';
import { useMeQuery } from 'types/graphql';

import { texts } from './AdminHeader.texts';

import styles from './AdminHeader.module.scss';

export const AdminHeader = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useMeQuery();
  const { addToast } = useToast();

  const getName = useCallback(() => {
    if (!data?.me) {
      return 'UserName';
    }
    return `${data?.me?.firstName} ${data?.me?.lastName}`;
  }, [data]);

  const getInitials = useCallback(() => {
    if (!data?.me) {
      return 'UN';
    }
    return `${data?.me?.firstName[0]}${data?.me?.lastName[0]}`;
  }, [data]);

  useEffect(() => {
    if (error) {
      addToast({
        title: 'Error',
        type: 'error'
      });
    }
  }, [addToast, error]);

  return (
    <header className={styles.root}>
      <div className={styles.left}>
        <Link to={BackofficePaths.Landing} className={styles.flex}>
          <StellarTechLogo className={styles.logo} />
        </Link>
        <Link
          to={BackofficePaths.Landing}
          className={({ isActive }) =>
            classNames(styles.link, {
              [styles.isActive]: isActive
            })
          }
        >
          <Button
            color="nav"
            iconLeft={{ name: 'house', font: 'hedemora' }}
            className={styles.navButton}
          >
            <FormattedMessage {...texts.home} />
          </Button>
        </Link>
        <Link
          to={BackofficePaths.customers}
          className={({ isActive }) =>
            classNames(styles.link, {
              [styles.isActive]: isActive
            })
          }
        >
          <Button
            color="nav"
            iconLeft={{ name: 'address-card', font: 'hedemora' }}
            className={styles.navButton}
          >
            <FormattedMessage {...texts.customers} />
          </Button>
        </Link>
        <Link
          to={BackofficePaths.settings}
          className={({ isActive }) =>
            classNames(styles.link, {
              [styles.isActive]: isActive
            })
          }
        >
          <Button
            color="nav"
            iconLeft={{ name: 'cog', font: 'hedemora' }}
            className={styles.navButton}
          >
            <FormattedMessage {...texts.settings} />
          </Button>
        </Link>
      </div>
      <div className={styles.right}>
        <div className={styles.logoutContainer}>
          <div className={styles.imageAndNameContainer}>
            {!loading ? (
              <>
                <div className={styles.profilePic}>{getInitials()}</div>
                <div className={styles.nameContainer}>
                  <div className={styles.profileName}>{getName()}</div>
                </div>
              </>
            ) : (
              <Spinner visible={loading} />
            )}
          </div>
          <Button
            stripPadding
            naked
            type="button"
            className={styles.logoutButton}
            onClick={() => dispatch(logoutStart())}
          >
            <Icon name="sign-out" className={styles.logoutIcon} />
          </Button>
        </div>
      </div>
    </header>
  );
};
