import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  heading: {
    id: 'SendMessageModal.heading',
    description: 'Send message modal heading text',
    defaultMessage: 'Skicka meddelandet?'
  },
  body: {
    id: 'SendMessageModal.body',
    description: 'Send message modal body text',
    defaultMessage: 'Detta meddelande kommer att skickas till '
  },
  cancel: {
    id: 'SendMessageModal.cancel',
    description: 'Send message modal cancel button',
    defaultMessage: 'Nej, avbryt'
  },
  confirm: {
    id: 'SendMessageModal.confirm',
    description: 'Send message modal confirm text',
    defaultMessage: 'Ja, skicka'
  },
  successHeading: {
    id: 'SendMessageModal.successHeading',
    description: 'Send message success text',
    defaultMessage: 'Meddelande skickat!'
  },
  successBody: {
    id: 'SendMessageModal.successBody',
    description: 'Send message success body text',
    defaultMessage: 'Meddelandet har skickats'
  },
  backToMessages: {
    id: 'SendMessageModal.backToMessages',
    description: 'Back to messages button text',
    defaultMessage: 'Tillbaka till Skicka meddelanden'
  }
});
