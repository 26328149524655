import { Outlet, Navigate } from 'react-router-dom';

import { ClientPaths } from 'consts/router';
import { useAuth } from 'hooks';
import { UserRole } from 'types/graphql';

export const PrivateRoute = () => {
  const { loggedIn, user } = useAuth();
  return loggedIn && user?.role !== UserRole.Admin ? (
    <Outlet />
  ) : (
    <Navigate to={ClientPaths.Login} replace />
  );
};
