import { useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Switch } from 'components/ui/forms';
import { useToast } from 'hooks';
import {
  useMeQuery,
  User,
  UsersDocument,
  useToggleBlockUserMutation
} from 'types/graphql';

import { texts } from './ToggleBlockSwitch.text';

interface ToggleBlockSwitchProps {
  user: User;
}

export const ToggleBlockSwitch = ({ user }: ToggleBlockSwitchProps) => {
  const { register, setValue } = useForm();
  const { addToast } = useToast();
  const { formatMessage } = useIntl();
  const [toggleBlock, { error: toggleBlockError }] =
    useToggleBlockUserMutation();
  const { data: meData } = useMeQuery();

  const onChange = useCallback(async () => {
    const { data } = await toggleBlock({
      variables: {
        id: user.id
      },
      refetchQueries: [UsersDocument]
    });

    if (data?.toggleBlockUser) {
      addToast({
        title: formatMessage(texts.toggleBlockSuccess),
        type: 'success'
      });
    }
  }, [addToast, formatMessage, toggleBlock, user.id]);

  useEffect(() => {
    if (toggleBlockError) {
      addToast({
        title: formatMessage(texts.toggleBlockError),
        type: 'error'
      });
    }
  }, [addToast, formatMessage, toggleBlockError]);

  useEffect(() => {
    setValue(user.id.toString(), user.blocked);
  }, [setValue, user.blocked, user.id]);

  return (
    <Switch
      name={user.id.toString()}
      register={register}
      onChange={onChange}
      defaultChecked={user.blocked}
      disabled={user.id === meData?.me?.id}
    />
  );
};
