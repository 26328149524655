import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  sendMessages: {
    id: 'Header.sendMessages',
    description: 'Send messages button text',
    defaultMessage: 'Skicka meddelanden'
  },
  contacts: {
    id: 'Header.contacts',
    description: 'Contacts button text',
    defaultMessage: 'Kontakter'
  },
  settings: {
    id: 'Header.settings',
    description: 'Settings button text',
    defaultMessage: 'Inställningar'
  },
  userNameError: {
    id: 'Header.userNameError',
    description: 'Error message when user name is not set',
    defaultMessage: 'Kunde inte hämta användarnamn'
  }
});
