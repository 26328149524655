import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.common,
  ...shared.form,
  heading: {
    id: 'SmsQuotaModal.heading',
    description: 'SMS quota modal heading text',
    defaultMessage: 'SMS-kvot'
  },
  body: {
    id: 'SmsQuotaModal.body',
    description: 'SMS quota modal body text',
    defaultMessage:
      'Sätt en gräns på hur många SMS kunden får skicka per månad.'
  },
  setQuotaSuccess: {
    id: 'SmsQuotaModal.setQuotaSuccess',
    description: 'SMS quota success text',
    defaultMessage: 'SMS-kvoten har uppdaterats.'
  },
  setQuotaError: {
    id: 'SmsQuotaModal.setQuotaError',
    description: 'SMS quota success text',
    defaultMessage: 'Kunde inte uppdatera SMS-kvoten.'
  }
});
