import { useContext, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { AccordionSelectors } from 'consts/cypress';
import { AccordionContext } from 'context/accordion';
import { passPropsToChildren } from 'utils';

import styles from '../Accordion.module.scss';

interface AccordionItemProps {
  children: ReactNode;
  id: string;
  className?: string;
  disabled?: boolean;
}

export const AccordionItem = ({
  children,
  className,
  id,
  ...props
}: AccordionItemProps) => {
  const [activeItems] = useContext(AccordionContext);
  const isActive = useMemo(() => activeItems.includes(id), [activeItems, id]);

  return (
    <div
      className={classNames(styles.item, className, {
        [styles.isActive]: isActive
      })}
      data-cy={AccordionSelectors.Item}
    >
      {passPropsToChildren(children, { ...props, id, isActive })}
    </div>
  );
};
