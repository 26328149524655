import { ReactNode, useCallback } from 'react';

import { Helmet, HelmetProps } from 'react-helmet-async';

import { OGTypes, TwitterCards } from 'types/seo';

interface SEOProps extends HelmetProps {
  children?: ReactNode;
  twitter?: {
    card: TwitterCards;
    title: string;
    description: string;
    site?: string;
    image: string;
    altImage?: string;
    creator?: string;
  };
  og?: {
    title: string;
    description: string;
    type?: OGTypes;
    image: string;
    altImage?: string;
    url: string;
    siteName?: string;
    locale?: string;
  };
  [key: string]: any;
}

export const SEO = ({ children, twitter, og, ...props }: SEOProps) => {
  const renderTwitter = useCallback(() => {
    if (twitter) {
      const {
        card,
        title = OGTypes.Website,
        description,
        site,
        image,
        altImage,
        creator
      } = twitter;

      return (
        <Helmet>
          <meta name="twitter:card" content={card} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          {site && <meta name="twitter:site" content={site} />}
          <meta name="twitter:image" content={image} />
          {altImage && <meta name="twitter:image:alt" content={altImage} />}
          {creator && <meta name="twitter:creator" content={creator} />}
        </Helmet>
      );
    }

    return null;
  }, [twitter]);

  const renderOG = useCallback(() => {
    if (og) {
      const {
        title,
        description,
        url,
        image,
        altImage,
        siteName,
        locale,
        type
      } = og;

      return (
        <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
          <meta property="og:image" content={image} />
          {altImage && <meta property="og:image:alt" content={altImage} />}
          {siteName && <meta property="og:site_name" content={siteName} />}
          {locale && <meta property="og:locale" content={locale} />}
          <meta property="og:type" content={type} />
        </Helmet>
      );
    }

    return null;
  }, [og]);

  return (
    <>
      <Helmet {...props}>{children}</Helmet>
      {renderTwitter()}
      {renderOG()}
    </>
  );
};
