import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Scalars } from 'types/graphql';

// Made to handle multiple jobs at once, ment to implement in the future.

// Types
interface JobsState {
  jobIds: Scalars['ID'][];
}

export interface JobsRootState {
  jobs: JobsState;
}

// Selectors
export const selectJobs = ({ jobs }: JobsRootState) => jobs;
export const selectJobIds = ({ jobs }: JobsRootState) => jobs.jobIds;

// Reducers
const initialState: JobsState = {
  jobIds: []
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    addJobId: (state, action: PayloadAction<Scalars['ID']>) => {
      if (state.jobIds.includes(action.payload)) return;
      state.jobIds = [...state.jobIds, ...[action.payload]];
    },
    removeJobId: (state, action: PayloadAction<Scalars['ID']>) => {
      state.jobIds = state.jobIds.filter((id) => id !== action.payload);
    },
    removeJobIds: (state, action: PayloadAction<Scalars['ID'][]>) => {
      state.jobIds = state.jobIds.filter((id) => !action.payload.includes(id));
    }
  }
});

export default jobsSlice.reducer;

// Actions
export const { addJobId, removeJobId, removeJobIds } = jobsSlice.actions;
