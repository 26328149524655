import classNames from 'classnames';
import { FieldErrors } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Information } from 'components/ui/general';
import { ErrorSelectors } from 'consts/cypress';

import { texts } from './Error.text';

import styles from './Error.module.scss';

interface ErrorProps {
  error?: FieldErrors;
  className?: string;
}

export const Error = ({ error, className }: ErrorProps) => {
  if (error) {
    return (
      <div
        className={classNames(styles.root, className)}
        data-cy={ErrorSelectors.Root}
      >
        <Information color="error">
          {error?.message || <FormattedMessage {...texts.message} />}
        </Information>
      </div>
    );
  }

  return null;
};
