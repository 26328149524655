import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from 'components/ui/general';
import { ConfirmDenyUserModal } from 'components/ui/modals';
import { Table, TableFetchData } from 'components/ui/table';
import { useToast } from 'hooks';
import { Scalars, useUsersLazyQuery } from 'types/graphql';
import { table } from 'utils';

import { texts } from './ApplicationsTable.text';

import styles from './ApplicationsTable.module.scss';

interface ApplicationsTableProps {
  searchTerm?: string;
}

export const ApplicationsTable: FC<ApplicationsTableProps> = ({
  searchTerm
}) => {
  const [selectedIds, setSelectedIds] = useState<Array<Scalars['ID']>>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [mutationType, setMutationType] = useState<string>('');
  const [userId, setUserId] = useState<Scalars['ID']>('');
  const toggleSelectedId = useCallback(
    (id: Scalars['ID']) => {
      if (!selectedIds.includes(id)) {
        return setSelectedIds([...selectedIds, ...[id]]);
      }
      setSelectedIds(selectedIds.filter((d) => d !== id));
    },
    [selectedIds]
  );

  const [fetchUsers, { data, loading, error }] = useUsersLazyQuery();
  const { addToast } = useToast();
  const { formatMessage } = useIntl();

  const handleClick = useCallback((user) => {
    setUserId(user.id);
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.fetchError),
        type: 'error'
      });
    }
  }, [addToast, error, formatMessage]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: TableFetchData) => {
      fetchUsers({
        variables: {
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
            ...(searchTerm ? { searchTerm } : {}),
            approved: false
          },
          sorting: {
            sorting: sortBy.map(({ id, desc }) => ({
              field: id,
              direction: desc ? 'desc' : 'asc'
            }))
          }
        }
      });
    },
    [fetchUsers, searchTerm]
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'pick',
        disableSortBy: true,
        Cell: ({
          row: {
            original: { id }
          }
        }: any) => {
          const name = selectedIds.includes(id) ? 'check-square' : 'square';
          const font = selectedIds.includes(id) ? 'hedemora' : undefined;
          return (
            <Button
              color="dark"
              iconRight={{ name, font }}
              naked
              stripPadding
              size="sm"
              onClick={() => toggleSelectedId(id)}
            />
          );
        }
      },
      {
        Header: <FormattedMessage {...texts.columnName} />,
        accessor: 'lastName',
        Cell: ({ row: { original } }: any) => {
          return `${original.firstName} ${original.lastName}` as string;
        }
      },
      {
        Header: <FormattedMessage {...texts.columnSenderName} />,
        accessor: 'senderName'
      },
      {
        Header: <FormattedMessage {...texts.columnEmail} />,
        accessor: 'email'
      },
      {
        Header: <FormattedMessage {...texts.columnMobileNumber} />,
        accessor: 'mobileNumber'
      },
      {
        Header: <FormattedMessage {...texts.columnOrganisationNumber} />,
        accessor: 'organizationNumber'
      },
      {
        accessor: 'confirmDeny',
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => (
          <div className={styles.modalButtonWrapper}>
            <Button
              color="success-light"
              name="accept"
              onClick={() => {
                setMutationType('accept');
                handleClick(original);
              }}
            >
              <FormattedMessage {...texts.accept} />
            </Button>
            <Button
              className={styles.deleteButtonMargin}
              color="error-light"
              name="deny"
              onClick={() => {
                setMutationType('deny');
                handleClick(original);
              }}
            >
              <FormattedMessage {...texts.delete} />
            </Button>
          </div>
        )
      }
    ],
    [handleClick, selectedIds, toggleSelectedId]
  );

  return (
    <>
      <div className={styles.root}>{searchTerm}</div>
      <Table
        columns={columns}
        data={data?.users.edges || []}
        onFetchData={fetchData}
        totalPages={table.getTotalPages(data?.users.meta)}
        pagination
        loading={loading}
        sort
        searchTermProp={searchTerm}
      />
      {isOpen && (
        <ConfirmDenyUserModal
          setIsOpen={setIsOpen}
          userId={userId}
          mutationType={mutationType}
        />
      )}
    </>
  );
};
