import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.common,
  ...shared.form,
  toggleBlockError: {
    id: 'ToggleBlockSwitch.toggleBlockError',
    description: 'Error message when toggling block',
    defaultMessage: 'Kunde inte ändra blockstatus'
  },
  toggleBlockSuccess: {
    id: 'ToggleBlockSwitch.toggleBlockSuccess',
    description: 'Success message when toggling block',
    defaultMessage: 'Blockstatus ändrad'
  }
});
