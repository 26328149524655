/**
 * When creating a path with params you should use a function as below:
 *
 * ExampleParams: (id?: number) => (id ? `/example/${id}` : `/example/:id`)
 * - Paths.ExampleParams() --> '/example/:id'
 * - Paths.ExampleParams(123) --> '/example/123'
 */

export enum Prefixes {
  Backoffice = 'backoffice'
}

// Backoffice paths
export const BackofficePaths = Object.freeze({
  Landing: `/${Prefixes.Backoffice}/`,
  customers: `/${Prefixes.Backoffice}/customers`,
  settings: `/${Prefixes.Backoffice}/settings`
});

// Client paths
export const ClientPaths = Object.freeze({
  Login: '/login',
  ResetPassword: '/reset-password/:email/:nonce',
  ForgotPassword: '/forgotpassword',
  SetPassword: '/set-password/:email/:nonce',
  SignUp: '/signup',
  SignedUp: '/signedup',
  Contacts: '/contacts',
  sendMessages: `/`,
  settings: `/settings`,
  OptOut: `/unsubscribe/:nonce`
});
