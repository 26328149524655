import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  confirmUser: {
    id: 'ConfirmDenyUserModal.confirmUser',
    description: 'Confirm user modal heading text',
    defaultMessage: 'Godkänn användare?'
  },
  deleteUser: {
    id: 'ConfirmDenyUserModal.deleteUser',
    description: 'Delete user modal heading text',
    defaultMessage: 'Radera användare?'
  },
  cancel: {
    id: 'ConfirmDenyUserModal.cancel',
    description: 'Send message modal cancel button',
    defaultMessage: 'Nej, avbryt'
  },
  confirm: {
    id: 'ConfirmDenyUserModal.confirm',
    description: 'Confirm user modal button text',
    defaultMessage: 'Ja, godkänn'
  },
  delete: {
    id: 'ConfirmDenyUserModal.delete',
    description: 'Delete user modal button text',
    defaultMessage: 'Ja, radera'
  },
  approveUserSuccess: {
    id: 'ConfirmDenyUserModal.approveUserSuccess',
    description: 'User approved toast title',
    defaultMessage: 'Användaren har godkänts'
  },
  deleteUserSuccess: {
    id: 'ConfirmDenyUserModal.deleteUserSuccess',
    description: 'User deleted toast title',
    defaultMessage: 'Användaren har raderats'
  },
  approveError: {
    id: 'ConfirmDenyUserModal.approveError',
    description: 'User approve error toast title',
    defaultMessage: 'Could not approve user'
  },
  deleteError: {
    id: 'ConfirmDenyUserModal.deleteError',
    description: 'User delete error toast title',
    defaultMessage: 'Could not delete user'
  }
});
