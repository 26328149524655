import { FieldMergeFunction, InMemoryCache } from '@apollo/client';

import introspectionResult from 'graphql/introspection-result.json';

const mergeEdges: FieldMergeFunction = (existing, incoming, { args }) => {
  const offset = args?.filter?.offset || 0;
  const merged = existing ? existing.edges.slice(0) : [];

  for (let index = 0; index < incoming.edges.length; index += 1) {
    merged[offset + index] = incoming.edges[index];
  }

  return {
    ...incoming,
    edges: merged
  };
};

export const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    User: {
      merge: true
    },
    Query: {
      fields: {
        users: {
          merge: mergeEdges
        }
      }
    }
  }
});
