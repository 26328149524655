import { lazy } from 'react';

export const client = {
  Login: lazy(() => import('./Login')),
  ResetPassword: lazy(() => import('./ResetPassword')),
  ForgotPassword: lazy(() => import('./ForgotPassword')),
  SetPassword: lazy(() => import('./SetPassword')),
  SignUp: lazy(() => import('./SignUp')),
  SignedUp: lazy(() => import('./SignedUp')),
  Contacts: lazy(() => import('./Contacts')),
  SendMessages: lazy(() => import('./SendMessages')),
  Settings: lazy(() => import('./Settings')),
  OptOut: lazy(() => import('./OptOut'))
};
