import { combineReducers } from '@reduxjs/toolkit';

import auth from 'redux/auth';
import gdpr from 'redux/gdpr';
import jobs from 'redux/jobs';
import router from 'redux/router';
import toast from 'redux/toast';

export const reducers = combineReducers({
  router,
  auth,
  toast,
  gdpr,
  jobs
});

export type RootState = ReturnType<typeof reducers>;
