import { useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { UploadCsvIcon } from 'assets/icons';
import { File, Select } from 'components/ui/forms';
import { Button } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { useToast } from 'hooks';
import {
  ContactsDocument,
  useContactListsQuery,
  useImportContactsFromCsvMutation
} from 'types/graphql';

import { texts } from './ImportContactsModal.text';

import styles from './ImportContactsModal.module.scss';

interface ImportContactsModalProps {
  showImportContacts: boolean;
  setShowImportContacts: (s: boolean) => void;
}

export const ImportContactsModal = ({
  showImportContacts,
  setShowImportContacts
}: ImportContactsModalProps) => {
  const [
    uploadCSV,
    { data: uploadData, loading: uploadLoading, error: uploadError }
  ] = useImportContactsFromCsvMutation();

  const {
    data: contactListsData,
    loading: contactListLoading,
    error: contactListsError
  } = useContactListsQuery();

  const { addToast } = useToast();
  const { register, handleSubmit, watch, control, reset } = useForm();
  const { formatMessage } = useIntl();

  const file = watch('importContacts');

  const onSubmit = useCallback(
    async ({ importContacts, list }) => {
      await uploadCSV({
        variables: {
          input: {
            file: importContacts[0],
            ...(!!list && { contactListId: list.value })
          }
        },
        refetchQueries: [ContactsDocument]
      });
      setShowImportContacts(false);
      reset();
    },
    [reset, setShowImportContacts, uploadCSV]
  );

  useEffect(() => {
    if (uploadError) {
      addToast({
        title: formatMessage(texts.uploadError),
        message: uploadError.message,
        type: 'error'
      });
    }
    if (contactListsError) {
      addToast({
        title: formatMessage(texts.chooseContactListError),
        message: contactListsError.message,
        type: 'error'
      });
    }
    if (uploadData && !uploadError) {
      addToast({
        title: formatMessage(texts.uploadSuccess),
        type: 'success'
      });
    }
  }, [addToast, formatMessage, uploadError, uploadData, contactListsError]);

  const selectOptions = useMemo(() => {
    if (contactListsData?.contactLists.edges) {
      return contactListsData.contactLists.edges.map((list) => ({
        label: list.name,
        value: list.id
      }));
    }
    return [];
  }, [contactListsData]);

  return (
    <Modal
      isOpen={showImportContacts}
      onClose={() => {
        reset();
        setShowImportContacts(false);
      }}
    >
      <div className={styles.root}>
        <h5>
          <FormattedMessage {...texts.importContacts} />
        </h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.content}>
            <div className={styles.center}>
              <UploadCsvIcon className={styles.icon} />
              <FormattedMessage {...texts.uploadCsv} />
              <File
                accept=".csv"
                name="importContacts"
                register={register}
                disabled={uploadLoading}
                showFileList
              >
                <Button
                  className={styles.chooseFileButton}
                  as="span"
                  color="tertiary"
                  size="xl"
                >
                  <FormattedMessage {...texts.buttonLabel} />
                </Button>
              </File>
            </div>
            <div />
            <div className={styles.ruleText}>
              <FormattedMessage {...texts.importRules} />
            </div>
          </div>
          <div className={styles.pickerWrapper}>
            <Select
              fullWidth
              control={control}
              name="list"
              options={selectOptions || []}
              placeholder={formatMessage(texts.chooseContactList)}
              size="lg"
              isClearable
              loading={contactListLoading}
              disabled={!!contactListsError}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.cancel}
              color="secondary"
              size="xl"
              onClick={() => {
                reset();
                setShowImportContacts(false);
              }}
            >
              <FormattedMessage {...texts.cancel} />
            </Button>
            <Button
              type="submit"
              size="xl"
              loading={uploadLoading}
              disabled={uploadLoading || !!uploadError || !file}
            >
              <FormattedMessage {...texts.uploadFile} />
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
