import { useCallback, useMemo } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

type HistoryAction = 'push' | 'replace';

export const useUrlQuery = (): {
  get: (name: string) => string | null;
  getAll: (name: string) => string[] | null;
  set: (name: string, value: string, action?: HistoryAction) => void;
  setAll: (
    items: { name: string; value: string }[],
    action?: HistoryAction
  ) => void;
  delete: (name: string, action?: HistoryAction) => void;
  deleteAll: (names: string[], action?: HistoryAction) => void;
  append: (name: string, value: string, action?: HistoryAction) => void;
  appendAll: (
    items: { name: string; value: string }[],
    action?: HistoryAction
  ) => void;
  hasNameWithValue: (name: string, value: string) => boolean;
  deleteNameWithValue: (
    name: string,
    value: string,
    action?: HistoryAction
  ) => void;
} => {
  const { search } = useLocation();
  const urlQuery = useMemo(() => new URLSearchParams(search), [search]);
  const navigate = useNavigate();
  const defaultAction: HistoryAction = 'push';

  const onGet = useCallback((name: string) => urlQuery.get(name), [urlQuery]);

  const onGetAll = useCallback(
    (name: string) => urlQuery.getAll(name),
    [urlQuery]
  );

  const onSet = useCallback(
    (name: string, value: string, action: HistoryAction = defaultAction) => {
      urlQuery.set(name, value);
      navigate(
        { search: urlQuery.toString() },
        { replace: action === 'replace' }
      );
    },
    [navigate, urlQuery]
  );

  const onSetAll = useCallback(
    (
      items: { name: string; value: string }[],
      action: HistoryAction = defaultAction
    ) => {
      items.forEach(({ name, value }) => urlQuery.set(name, value));
      navigate(
        { search: urlQuery.toString() },
        { replace: action === 'replace' }
      );
    },
    [navigate, urlQuery]
  );

  const onDelete = useCallback(
    (name: string, action: HistoryAction = defaultAction) => {
      urlQuery.delete(name);
      navigate(
        { search: urlQuery.toString() },
        { replace: action === 'replace' }
      );
    },
    [navigate, urlQuery]
  );

  const onDeleteAll = useCallback(
    (names: string[], action: HistoryAction = defaultAction) => {
      names.forEach((name) => urlQuery.delete(name));
      navigate(
        { search: urlQuery.toString() },
        { replace: action === 'replace' }
      );
    },
    [navigate, urlQuery]
  );

  const onAppend = useCallback(
    (name: string, value: string, action: HistoryAction = defaultAction) => {
      urlQuery.append(name, value);
      navigate(
        { search: urlQuery.toString() },
        { replace: action === 'replace' }
      );
    },
    [navigate, urlQuery]
  );

  const onAppendAll = useCallback(
    (
      items: { name: string; value: string }[],
      action: HistoryAction = defaultAction
    ) => {
      items.forEach(({ name, value }) => urlQuery.append(name, value));
      navigate(
        { search: urlQuery.toString() },
        { replace: action === 'replace' }
      );
    },
    [navigate, urlQuery]
  );

  const onHasNameWithValue = useCallback(
    (name: string, value: string) => {
      let hasNameWithValue = false;

      urlQuery.forEach((urlValue, urlName) => {
        if (urlValue === value && urlName === name) {
          hasNameWithValue = true;
        }
      });

      return hasNameWithValue;
    },
    [urlQuery]
  );

  const onDeleteNameWithValue = useCallback(
    (name: string, value: string, action: HistoryAction = defaultAction) => {
      const collectNameQuery = urlQuery.getAll(name);

      // Delete everything with name
      urlQuery.delete(name);

      // Re-append every value except the one that should be deleted
      collectNameQuery
        .filter((urlValue) => urlValue !== value)
        .forEach((urlValue) => urlQuery.append(name, urlValue));

      navigate(
        { search: urlQuery.toString() },
        { replace: action === 'replace' }
      );
    },
    [navigate, urlQuery]
  );

  return {
    get: onGet,
    getAll: onGetAll,
    set: onSet,
    setAll: onSetAll,
    delete: onDelete,
    deleteAll: onDeleteAll,
    append: onAppend,
    appendAll: onAppendAll,
    hasNameWithValue: onHasNameWithValue,
    deleteNameWithValue: onDeleteNameWithValue
  };
};
