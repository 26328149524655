import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.common,
  ...shared.table,
  ...shared.form,
  heading: {
    id: 'SmsHistoryModal.heading',
    description: 'Sms history modal heading',
    defaultMessage: 'SMS-Historik'
  },
  downloadReport: {
    id: 'SmsHistoryModal.downloadReport',
    description: 'Button text for downloading sms history',
    defaultMessage: 'Ladda ner rapport för '
  },
  getMessagesError: {
    id: 'SmsHistoryModal.getMessagesError',
    description: 'Error message for getting messages',
    defaultMessage: 'Kunde inte hämta meddelanden.'
  },
  exportError: {
    id: 'SmsHistoryModal.exportError',
    description: 'Error message for exporting messages',
    defaultMessage: 'Kunde inte exportera meddelanden.'
  },
  noMessages: {
    id: 'SmsHistoryModal.noMessages',
    description: 'No messages info text',
    defaultMessage: 'Det finns inga meddelanden att visa'
  },
  emptyTableMessage: {
    id: 'SmsHistoryModal.emptyTableMessage',
    description: 'Message for empty table view',
    defaultMessage: 'Välj månad för att se meddelanden'
  },
  noReceiver: {
    id: 'SmsHistoryModal.noReceiver',
    description: 'Message for no message receiver',
    defaultMessage: 'Hittade ingen mottagare'
  },
  downloadError: {
    id: 'SmsHistoryModal.downloadError',
    description: 'Could not download history error message',
    defaultMessage: 'Kunde inte ladda ner historik'
  },
  downloadSuccess: {
    id: 'SmsHistoryModal.downloadSuccess',
    description: 'Download success message',
    defaultMessage: 'SMS-historik nerladdad'
  }
});
