import { ComponentProps, useMemo } from 'react';

import { components } from 'react-select';

import styles from '../Select.module.scss';

export const Control = (props: ComponentProps<typeof components.Control>) => {
  const { selectProps }: { [key: string]: any } = props;
  const { label, validation } = selectProps;
  const Root = useMemo(() => (label ? 'label' : 'div'), [label]);

  return (
    <Root>
      {!!label && (
        <div className={styles.label}>
          {label}
          {validation.required ? ' *' : ''}
        </div>
      )}
      <components.Control {...props} />
    </Root>
  );
};
