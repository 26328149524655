import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Receiver": [
      "Contact",
      "ContactList"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Receiver": [
      "Contact",
      "ContactList"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string | number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Uint32: any;
  Uint64: any;
  Upload: any;
};

export type AddRemoveContactListContactsInput = {
  contactIds: Array<Scalars['ID']>;
  contactListId: Scalars['ID'];
};

export type AuthenticationResponse = {
  __typename?: 'AuthenticationResponse';
  jwt: Scalars['String'];
  message: Scalars['String'];
  newAccount: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  success: Scalars['Boolean'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type BaseFilter = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  createdAt: Scalars['Time'];
  createdById: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type ContactCollection = {
  __typename?: 'ContactCollection';
  edges: Array<Contact>;
  meta: PageInfo;
};

export type ContactFilter = {
  contactListId?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<TimeRangeFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  messageId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type ContactList = {
  __typename?: 'ContactList';
  contactCount: Scalars['Int'];
  createdAt: Scalars['Time'];
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type ContactListCollection = {
  __typename?: 'ContactListCollection';
  edges: Array<ContactList>;
  meta: PageInfo;
};

export type ContactListFilter = {
  createdAt?: InputMaybe<TimeRangeFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  /** will check if email is available, else return error */
  email: Scalars['String'];
  firstName: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  organizationNumber?: InputMaybe<Scalars['String']>;
  /** min length 8, will create blocked user if no password, used to apply for user */
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  senderName: Scalars['String'];
};

export type FloatRangeFilter = {
  from?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['Float']>;
};

export type Image = {
  __typename?: 'Image';
  contentType: Scalars['String'];
  createdAt: Scalars['Time'];
  filename: Scalars['String'];
  height: Scalars['Uint32'];
  id: Scalars['ID'];
  originalName: Scalars['String'];
  sizeInBytes: Scalars['Uint64'];
  uri: Scalars['String'];
  width: Scalars['Uint32'];
};

export type ImageCollection = {
  __typename?: 'ImageCollection';
  edges: Array<Image>;
  meta: PageInfo;
};

export type ImageFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ImportContactsFromCsvInput = {
  contactListId?: InputMaybe<Scalars['ID']>;
  file: Scalars['Upload'];
};

export type IntRangeFilter = {
  from?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
};

export type Job = {
  __typename?: 'Job';
  completedAt?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  status: JobStatus;
  type: JobType;
};

export type JobCollection = {
  __typename?: 'JobCollection';
  edges: Array<Job>;
  meta: PageInfo;
};

export type JobFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum JobStatus {
  Completed = 'completed',
  Failed = 'failed',
  Started = 'started'
}

export enum JobType {
  SendSms = 'send_sms'
}

export type Message = {
  __typename?: 'Message';
  amount: Scalars['Int'];
  createdAt: Scalars['Time'];
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  message: Scalars['String'];
  receiver?: Maybe<Receiver>;
  sender: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type MessageCollection = {
  __typename?: 'MessageCollection';
  edges: Array<Message>;
  meta: PageInfo;
};

export type MessageFilter = {
  contactId?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<TimeRangeFilter>;
  createdById?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** addContactListContacts, upsert a contact group with the input given */
  addContactListContacts: ContactList;
  /** approveUser, takes the id of the unapproved user, sets approved timestamp and unblocks it */
  approveUser: User;
  /** changePassword, change the password of the user */
  changePassword: NoContent;
  /** createUser, create a new user */
  createUser: AuthenticationResponse;
  /** deleteContact, delete the contact with the corresponding ID if nounce is valid */
  deleteContact: NoContent;
  /** deleteContactList, upsert a contact group with the input given */
  deleteContactList: NoContent;
  /** deleteContacts, deletes the contacts with the corresponding IDs */
  deleteContacts: NoContent;
  /** deleteUser, removes a user in the system */
  deleteUser: NoContent;
  /** importContactsFromCsv, deletes the contact with the corresponding ID */
  importContactsFromCsv: Array<Contact>;
  /** loginEmail, This endpoint is used by users with a valid email and password. Get access to the website with the jwt token */
  loginEmail: AuthenticationResponse;
  /** reSendUserInvite, resend the activation email */
  reSendUserInvite: NoContent;
  /** refreshToken, called when jwt has expired. Param, the refresh token, returns new AuthenticationResponse */
  refreshToken: AuthenticationResponse;
  /** removeContactListContacts, upsert a contact group with the input given */
  removeContactListContacts: ContactList;
  /** resendVerifyEmail, resends the users email */
  resendVerifyEmail?: Maybe<User>;
  /** resetPasswordFinalize, finilize the reset of password and returns a jwt token. */
  resetPasswordFinalize: AuthenticationResponse;
  /** resetPasswordInitiate, sends an email to the user for initiation of reset password. */
  resetPasswordInitiate: NoContent;
  /**
   * sendMessage, send a message to contacts with message given in input
   * Messages are sent in separate routine, use Job to get status of sent message
   */
  sendMessage?: Maybe<Job>;
  /**
   * sendMessageToContactList, send a message to contacts list with message given in input
   * Messages are sent in separate routine, use Job to get status of sent message
   */
  sendMessageToContactList?: Maybe<Job>;
  /** setUserQuota sets the monthly SMS quota for a user */
  setUserQuota?: Maybe<User>;
  /** toggleBlockUser, takes the id of the user and toggles its current blocked state */
  toggleBlockUser: User;
  /** updateUser, update a user in the system */
  updateUser?: Maybe<User>;
  /** upsertContact, upsert a contact with the input given */
  upsertContact: Contact;
  /** upsertContactList, upsert a contact group with the input given */
  upsertContactList: ContactList;
  /** verifyEmail, sets user  email to verified, consuming nonce */
  verifyEmail?: Maybe<User>;
};


export type MutationAddContactListContactsArgs = {
  input: AddRemoveContactListContactsInput;
};


export type MutationApproveUserArgs = {
  id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteContactArgs = {
  nonce: Scalars['String'];
};


export type MutationDeleteContactListArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactsArgs = {
  input: Array<Scalars['ID']>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationImportContactsFromCsvArgs = {
  input?: InputMaybe<ImportContactsFromCsvInput>;
};


export type MutationLoginEmailArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationReSendUserInviteArgs = {
  id: Scalars['ID'];
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationRemoveContactListContactsArgs = {
  input: AddRemoveContactListContactsInput;
};


export type MutationResendVerifyEmailArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordFinalizeArgs = {
  input: ResetPasswordFinalizeInput;
};


export type MutationResetPasswordInitiateArgs = {
  email: Scalars['String'];
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationSendMessageToContactListArgs = {
  input: SendContactListMessageInput;
};


export type MutationSetUserQuotaArgs = {
  quota: Scalars['Uint32'];
  userId: Scalars['ID'];
};


export type MutationToggleBlockUserArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};


export type MutationUpsertContactArgs = {
  input: UpsertContactInput;
};


export type MutationUpsertContactListArgs = {
  input: UpsertContactListInput;
};


export type MutationVerifyEmailArgs = {
  email: Scalars['String'];
  nonce: Scalars['String'];
};

export type NoContent = {
  __typename?: 'NoContent';
  success: Scalars['Boolean'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** contact, fetches the contact with the corresponding ID, Auth required */
  contact?: Maybe<Contact>;
  /** contact, fetches the contact with the corresponding ID, Auth required */
  contactList?: Maybe<ContactList>;
  /** contact, fetches multiple contacts, Auth required */
  contactLists: ContactListCollection;
  /** contact, fetches multiple contacts, Auth required */
  contacts: ContactCollection;
  /** exportMessageHistory returns a string for csv export */
  exportMessageHistory?: Maybe<Scalars['String']>;
  /** job, fetches the job with the corresponding ID, Auth required */
  job?: Maybe<Job>;
  /** jobs, fetches multiple jobs, Auth required */
  jobs: JobCollection;
  me?: Maybe<User>;
  /** message, fetches the message with the corresponding ID, Auth required */
  message?: Maybe<Message>;
  /** messages, fetches multiple messages, Auth required */
  messages: MessageCollection;
  user?: Maybe<User>;
  /** users, required role [company_user, company_admin, admin] */
  users: UserCollection;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryContactListArgs = {
  id: Scalars['ID'];
};


export type QueryContactListsArgs = {
  filter?: InputMaybe<ContactListFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryContactsArgs = {
  filter?: InputMaybe<ContactFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryExportMessageHistoryArgs = {
  filter?: InputMaybe<MessageFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryJobArgs = {
  id: Scalars['ID'];
};


export type QueryJobsArgs = {
  filter?: InputMaybe<JobFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryMessagesArgs = {
  filter?: InputMaybe<MessageFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type Receiver = Contact | ContactList;

export type ResetPasswordFinalizeInput = {
  email: Scalars['String'];
  nonce: Scalars['String'];
  password: Scalars['String'];
};

export type SendContactListMessageInput = {
  contactListId: Scalars['ID'];
  message: Scalars['String'];
  unsubscribe: Scalars['Boolean'];
};

export type SendMessageInput = {
  contactIds: Array<Scalars['ID']>;
  message: Scalars['String'];
  unsubscribe: Scalars['Boolean'];
};

export type SortOrdering = {
  sorting?: InputMaybe<Array<SortOrderingInput>>;
};

export type SortOrderingInput = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type TimeRangeFilter = {
  from: Scalars['Time'];
  to: Scalars['Time'];
};

export type UpdateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  senderName?: InputMaybe<Scalars['String']>;
};

export type UpsertContactInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
};

export type UpsertContactListInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  approved: Scalars['Boolean'];
  blocked: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Image>;
  lastName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  organizationNumber?: Maybe<Scalars['String']>;
  quota: Scalars['Uint32'];
  remainingSMSQuota: Scalars['Int'];
  role: UserRole;
  senderName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
};

export type UserCollection = {
  __typename?: 'UserCollection';
  edges: Array<User>;
  meta: PageInfo;
};

export type UserFilter = {
  approved?: InputMaybe<Scalars['Boolean']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<TimeRangeFilter>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<UserRole>>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'admin',
  User = 'user'
}

export type CustomUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'firstName' | 'id' | 'lastName'>
  )> }
);

export type ContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContactQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'createdAt' | 'createdById' | 'email' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'updatedAt'>
  )> }
);

export type ContactListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContactListQuery = (
  { __typename?: 'Query' }
  & { contactList?: Maybe<(
    { __typename?: 'ContactList' }
    & Pick<ContactList, 'contactCount' | 'createdAt' | 'createdById' | 'id' | 'name' | 'updatedAt'>
  )> }
);

export type ContactListsQueryVariables = Exact<{
  filter?: InputMaybe<ContactListFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type ContactListsQuery = (
  { __typename?: 'Query' }
  & { contactLists: (
    { __typename?: 'ContactListCollection' }
    & { edges: Array<(
      { __typename?: 'ContactList' }
      & Pick<ContactList, 'contactCount' | 'createdAt' | 'createdById' | 'id' | 'name' | 'updatedAt'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ContactsQueryVariables = Exact<{
  filter?: InputMaybe<ContactFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type ContactsQuery = (
  { __typename?: 'Query' }
  & { contacts: (
    { __typename?: 'ContactCollection' }
    & { edges: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'createdAt' | 'createdById' | 'email' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'updatedAt'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ExportMessageHistoryQueryVariables = Exact<{
  filter?: InputMaybe<MessageFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type ExportMessageHistoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'exportMessageHistory'>
);

export type JobQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobQuery = (
  { __typename?: 'Query' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'completedAt' | 'createdAt' | 'id' | 'status' | 'type'>
  )> }
);

export type JobsQueryVariables = Exact<{
  filter?: InputMaybe<JobFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type JobsQuery = (
  { __typename?: 'Query' }
  & { jobs: (
    { __typename?: 'JobCollection' }
    & { edges: Array<(
      { __typename?: 'Job' }
      & Pick<Job, 'completedAt' | 'createdAt' | 'id' | 'status' | 'type'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type MessageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MessageQuery = (
  { __typename?: 'Query' }
  & { message?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'amount' | 'createdAt' | 'createdById' | 'id' | 'message' | 'sender' | 'updatedAt'>
    & { receiver?: Maybe<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'createdAt' | 'createdById' | 'email' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'updatedAt'>
    ) | (
      { __typename?: 'ContactList' }
      & Pick<ContactList, 'contactCount' | 'createdAt' | 'createdById' | 'id' | 'name' | 'updatedAt'>
    )> }
  )> }
);

export type MessagesQueryVariables = Exact<{
  filter?: InputMaybe<MessageFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type MessagesQuery = (
  { __typename?: 'Query' }
  & { messages: (
    { __typename?: 'MessageCollection' }
    & { edges: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'amount' | 'createdAt' | 'createdById' | 'id' | 'message' | 'sender' | 'updatedAt'>
      & { receiver?: Maybe<(
        { __typename?: 'Contact' }
        & Pick<Contact, 'createdAt' | 'createdById' | 'email' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'updatedAt'>
      ) | (
        { __typename?: 'ContactList' }
        & Pick<ContactList, 'contactCount' | 'createdAt' | 'createdById' | 'id' | 'name' | 'updatedAt'>
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type AddContactListContactsMutationVariables = Exact<{
  input: AddRemoveContactListContactsInput;
}>;


export type AddContactListContactsMutation = (
  { __typename?: 'Mutation' }
  & { addContactListContacts: (
    { __typename?: 'ContactList' }
    & Pick<ContactList, 'contactCount' | 'createdAt' | 'createdById' | 'id' | 'name' | 'updatedAt'>
  ) }
);

export type ApproveUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveUserMutation = (
  { __typename?: 'Mutation' }
  & { approveUser: (
    { __typename?: 'User' }
    & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )> }
  ) }
);

export type DeleteContactMutationVariables = Exact<{
  nonce: Scalars['String'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteContactListMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactListMutation = (
  { __typename?: 'Mutation' }
  & { deleteContactList: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteContactsMutationVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteContactsMutation = (
  { __typename?: 'Mutation' }
  & { deleteContacts: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type ImportContactsFromCsvMutationVariables = Exact<{
  input?: InputMaybe<ImportContactsFromCsvInput>;
}>;


export type ImportContactsFromCsvMutation = (
  { __typename?: 'Mutation' }
  & { importContactsFromCsv: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'createdAt' | 'createdById' | 'email' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'updatedAt'>
  )> }
);

export type LoginEmailMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginEmailMutation = (
  { __typename?: 'Mutation' }
  & { loginEmail: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )> }
  ) }
);

export type ReSendUserInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReSendUserInviteMutation = (
  { __typename?: 'Mutation' }
  & { reSendUserInvite: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )> }
  ) }
);

export type RemoveContactListContactsMutationVariables = Exact<{
  input: AddRemoveContactListContactsInput;
}>;


export type RemoveContactListContactsMutation = (
  { __typename?: 'Mutation' }
  & { removeContactListContacts: (
    { __typename?: 'ContactList' }
    & Pick<ContactList, 'contactCount' | 'createdAt' | 'createdById' | 'id' | 'name' | 'updatedAt'>
  ) }
);

export type ResendVerifyEmailMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResendVerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { resendVerifyEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type ResetPasswordFinalizeMutationVariables = Exact<{
  input: ResetPasswordFinalizeInput;
}>;


export type ResetPasswordFinalizeMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordFinalize: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )> }
  ) }
);

export type ResetPasswordInitiateMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordInitiateMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordInitiate: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendMessage?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'completedAt' | 'createdAt' | 'id' | 'status' | 'type'>
  )> }
);

export type SendMessageToContactListMutationVariables = Exact<{
  input: SendContactListMessageInput;
}>;


export type SendMessageToContactListMutation = (
  { __typename?: 'Mutation' }
  & { sendMessageToContactList?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'completedAt' | 'createdAt' | 'id' | 'status' | 'type'>
  )> }
);

export type SetUserQuotaMutationVariables = Exact<{
  quota: Scalars['Uint32'];
  userId: Scalars['ID'];
}>;


export type SetUserQuotaMutation = (
  { __typename?: 'Mutation' }
  & { setUserQuota?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type ToggleBlockUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleBlockUserMutation = (
  { __typename?: 'Mutation' }
  & { toggleBlockUser: (
    { __typename?: 'User' }
    & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type UpsertContactMutationVariables = Exact<{
  input: UpsertContactInput;
}>;


export type UpsertContactMutation = (
  { __typename?: 'Mutation' }
  & { upsertContact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'createdAt' | 'createdById' | 'email' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'updatedAt'>
  ) }
);

export type UpsertContactListMutationVariables = Exact<{
  input: UpsertContactListInput;
}>;


export type UpsertContactListMutation = (
  { __typename?: 'Mutation' }
  & { upsertContactList: (
    { __typename?: 'ContactList' }
    & Pick<ContactList, 'contactCount' | 'createdAt' | 'createdById' | 'id' | 'name' | 'updatedAt'>
  ) }
);

export type VerifyEmailMutationVariables = Exact<{
  email: Scalars['String'];
  nonce: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'approved' | 'blocked' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'firstName' | 'id' | 'lastName' | 'mobileNumber' | 'organizationNumber' | 'quota' | 'remainingSMSQuota' | 'role' | 'senderName' | 'updatedAt'>
    & { image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);


export const CustomUserDocument = gql`
    query CustomUser($id: ID!) {
  user(id: $id) {
    email
    firstName
    id
    lastName
  }
}
    `;

/**
 * __useCustomUserQuery__
 *
 * To run a query within a React component, call `useCustomUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomUserQuery(baseOptions: Apollo.QueryHookOptions<CustomUserQuery, CustomUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomUserQuery, CustomUserQueryVariables>(CustomUserDocument, options);
      }
export function useCustomUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomUserQuery, CustomUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomUserQuery, CustomUserQueryVariables>(CustomUserDocument, options);
        }
export type CustomUserQueryHookResult = ReturnType<typeof useCustomUserQuery>;
export type CustomUserLazyQueryHookResult = ReturnType<typeof useCustomUserLazyQuery>;
export type CustomUserQueryResult = Apollo.QueryResult<CustomUserQuery, CustomUserQueryVariables>;
export const ContactDocument = gql`
    query Contact($id: ID!) {
  contact(id: $id) {
    createdAt
    createdById
    email
    firstName
    id
    lastName
    mobileNumber
    updatedAt
  }
}
    `;

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactQuery(baseOptions: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
      }
export function useContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactQuery, ContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
        }
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactQueryResult = Apollo.QueryResult<ContactQuery, ContactQueryVariables>;
export const ContactListDocument = gql`
    query ContactList($id: ID!) {
  contactList(id: $id) {
    contactCount
    createdAt
    createdById
    id
    name
    updatedAt
  }
}
    `;

/**
 * __useContactListQuery__
 *
 * To run a query within a React component, call `useContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactListQuery(baseOptions: Apollo.QueryHookOptions<ContactListQuery, ContactListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactListQuery, ContactListQueryVariables>(ContactListDocument, options);
      }
export function useContactListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactListQuery, ContactListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactListQuery, ContactListQueryVariables>(ContactListDocument, options);
        }
export type ContactListQueryHookResult = ReturnType<typeof useContactListQuery>;
export type ContactListLazyQueryHookResult = ReturnType<typeof useContactListLazyQuery>;
export type ContactListQueryResult = Apollo.QueryResult<ContactListQuery, ContactListQueryVariables>;
export const ContactListsDocument = gql`
    query ContactLists($filter: ContactListFilter, $sorting: SortOrdering) {
  contactLists(filter: $filter, sorting: $sorting) {
    edges {
      contactCount
      createdAt
      createdById
      id
      name
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useContactListsQuery__
 *
 * To run a query within a React component, call `useContactListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContactListsQuery(baseOptions?: Apollo.QueryHookOptions<ContactListsQuery, ContactListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactListsQuery, ContactListsQueryVariables>(ContactListsDocument, options);
      }
export function useContactListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactListsQuery, ContactListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactListsQuery, ContactListsQueryVariables>(ContactListsDocument, options);
        }
export type ContactListsQueryHookResult = ReturnType<typeof useContactListsQuery>;
export type ContactListsLazyQueryHookResult = ReturnType<typeof useContactListsLazyQuery>;
export type ContactListsQueryResult = Apollo.QueryResult<ContactListsQuery, ContactListsQueryVariables>;
export const ContactsDocument = gql`
    query Contacts($filter: ContactFilter, $sorting: SortOrdering) {
  contacts(filter: $filter, sorting: $sorting) {
    edges {
      createdAt
      createdById
      email
      firstName
      id
      lastName
      mobileNumber
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useContactsQuery(baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
      }
export function useContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>;
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>;
export const ExportMessageHistoryDocument = gql`
    query ExportMessageHistory($filter: MessageFilter, $sorting: SortOrdering) {
  exportMessageHistory(filter: $filter, sorting: $sorting)
}
    `;

/**
 * __useExportMessageHistoryQuery__
 *
 * To run a query within a React component, call `useExportMessageHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMessageHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMessageHistoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useExportMessageHistoryQuery(baseOptions?: Apollo.QueryHookOptions<ExportMessageHistoryQuery, ExportMessageHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportMessageHistoryQuery, ExportMessageHistoryQueryVariables>(ExportMessageHistoryDocument, options);
      }
export function useExportMessageHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportMessageHistoryQuery, ExportMessageHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportMessageHistoryQuery, ExportMessageHistoryQueryVariables>(ExportMessageHistoryDocument, options);
        }
export type ExportMessageHistoryQueryHookResult = ReturnType<typeof useExportMessageHistoryQuery>;
export type ExportMessageHistoryLazyQueryHookResult = ReturnType<typeof useExportMessageHistoryLazyQuery>;
export type ExportMessageHistoryQueryResult = Apollo.QueryResult<ExportMessageHistoryQuery, ExportMessageHistoryQueryVariables>;
export const JobDocument = gql`
    query Job($id: ID!) {
  job(id: $id) {
    completedAt
    createdAt
    id
    status
    type
  }
}
    `;

/**
 * __useJobQuery__
 *
 * To run a query within a React component, call `useJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobQuery(baseOptions: Apollo.QueryHookOptions<JobQuery, JobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobQuery, JobQueryVariables>(JobDocument, options);
      }
export function useJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobQuery, JobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobQuery, JobQueryVariables>(JobDocument, options);
        }
export type JobQueryHookResult = ReturnType<typeof useJobQuery>;
export type JobLazyQueryHookResult = ReturnType<typeof useJobLazyQuery>;
export type JobQueryResult = Apollo.QueryResult<JobQuery, JobQueryVariables>;
export const JobsDocument = gql`
    query Jobs($filter: JobFilter, $sorting: SortOrdering) {
  jobs(filter: $filter, sorting: $sorting) {
    edges {
      completedAt
      createdAt
      id
      status
      type
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useJobsQuery(baseOptions?: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    approved
    blocked
    createdAt
    deletedAt
    email
    emailVerified
    firstName
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    lastName
    mobileNumber
    organizationNumber
    quota
    remainingSMSQuota
    role
    senderName
    updatedAt
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MessageDocument = gql`
    query Message($id: ID!) {
  message(id: $id) {
    amount
    createdAt
    createdById
    id
    message
    receiver {
      ... on Contact {
        createdAt
        createdById
        email
        firstName
        id
        lastName
        mobileNumber
        updatedAt
      }
      ... on ContactList {
        contactCount
        createdAt
        createdById
        id
        name
        updatedAt
      }
    }
    sender
    updatedAt
  }
}
    `;

/**
 * __useMessageQuery__
 *
 * To run a query within a React component, call `useMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageQuery(baseOptions: Apollo.QueryHookOptions<MessageQuery, MessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageQuery, MessageQueryVariables>(MessageDocument, options);
      }
export function useMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageQuery, MessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageQuery, MessageQueryVariables>(MessageDocument, options);
        }
export type MessageQueryHookResult = ReturnType<typeof useMessageQuery>;
export type MessageLazyQueryHookResult = ReturnType<typeof useMessageLazyQuery>;
export type MessageQueryResult = Apollo.QueryResult<MessageQuery, MessageQueryVariables>;
export const MessagesDocument = gql`
    query Messages($filter: MessageFilter, $sorting: SortOrdering) {
  messages(filter: $filter, sorting: $sorting) {
    edges {
      amount
      createdAt
      createdById
      id
      message
      receiver {
        ... on Contact {
          createdAt
          createdById
          email
          firstName
          id
          lastName
          mobileNumber
          updatedAt
        }
        ... on ContactList {
          contactCount
          createdAt
          createdById
          id
          name
          updatedAt
        }
      }
      sender
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions?: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    approved
    blocked
    createdAt
    deletedAt
    email
    emailVerified
    firstName
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    lastName
    mobileNumber
    organizationNumber
    quota
    remainingSMSQuota
    role
    senderName
    updatedAt
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($filter: UserFilter, $sorting: SortOrdering) {
  users(filter: $filter, sorting: $sorting) {
    edges {
      approved
      blocked
      createdAt
      deletedAt
      email
      emailVerified
      firstName
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      lastName
      mobileNumber
      organizationNumber
      quota
      remainingSMSQuota
      role
      senderName
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const AddContactListContactsDocument = gql`
    mutation AddContactListContacts($input: AddRemoveContactListContactsInput!) {
  addContactListContacts(input: $input) {
    contactCount
    createdAt
    createdById
    id
    name
    updatedAt
  }
}
    `;
export type AddContactListContactsMutationFn = Apollo.MutationFunction<AddContactListContactsMutation, AddContactListContactsMutationVariables>;

/**
 * __useAddContactListContactsMutation__
 *
 * To run a mutation, you first call `useAddContactListContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactListContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactListContactsMutation, { data, loading, error }] = useAddContactListContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactListContactsMutation(baseOptions?: Apollo.MutationHookOptions<AddContactListContactsMutation, AddContactListContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddContactListContactsMutation, AddContactListContactsMutationVariables>(AddContactListContactsDocument, options);
      }
export type AddContactListContactsMutationHookResult = ReturnType<typeof useAddContactListContactsMutation>;
export type AddContactListContactsMutationResult = Apollo.MutationResult<AddContactListContactsMutation>;
export type AddContactListContactsMutationOptions = Apollo.BaseMutationOptions<AddContactListContactsMutation, AddContactListContactsMutationVariables>;
export const ApproveUserDocument = gql`
    mutation ApproveUser($id: ID!) {
  approveUser(id: $id) {
    approved
    blocked
    createdAt
    deletedAt
    email
    emailVerified
    firstName
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    lastName
    mobileNumber
    organizationNumber
    quota
    remainingSMSQuota
    role
    senderName
    updatedAt
  }
}
    `;
export type ApproveUserMutationFn = Apollo.MutationFunction<ApproveUserMutation, ApproveUserMutationVariables>;

/**
 * __useApproveUserMutation__
 *
 * To run a mutation, you first call `useApproveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveUserMutation, { data, loading, error }] = useApproveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveUserMutation(baseOptions?: Apollo.MutationHookOptions<ApproveUserMutation, ApproveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveUserMutation, ApproveUserMutationVariables>(ApproveUserDocument, options);
      }
export type ApproveUserMutationHookResult = ReturnType<typeof useApproveUserMutation>;
export type ApproveUserMutationResult = Apollo.MutationResult<ApproveUserMutation>;
export type ApproveUserMutationOptions = Apollo.BaseMutationOptions<ApproveUserMutation, ApproveUserMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    success
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      approved
      blocked
      createdAt
      deletedAt
      email
      emailVerified
      firstName
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      lastName
      mobileNumber
      organizationNumber
      quota
      remainingSMSQuota
      role
      senderName
      updatedAt
    }
    userId
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($nonce: String!) {
  deleteContact(nonce: $nonce) {
    success
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      nonce: // value for 'nonce'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const DeleteContactListDocument = gql`
    mutation DeleteContactList($id: ID!) {
  deleteContactList(id: $id) {
    success
  }
}
    `;
export type DeleteContactListMutationFn = Apollo.MutationFunction<DeleteContactListMutation, DeleteContactListMutationVariables>;

/**
 * __useDeleteContactListMutation__
 *
 * To run a mutation, you first call `useDeleteContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactListMutation, { data, loading, error }] = useDeleteContactListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactListMutation, DeleteContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactListMutation, DeleteContactListMutationVariables>(DeleteContactListDocument, options);
      }
export type DeleteContactListMutationHookResult = ReturnType<typeof useDeleteContactListMutation>;
export type DeleteContactListMutationResult = Apollo.MutationResult<DeleteContactListMutation>;
export type DeleteContactListMutationOptions = Apollo.BaseMutationOptions<DeleteContactListMutation, DeleteContactListMutationVariables>;
export const DeleteContactsDocument = gql`
    mutation DeleteContacts($input: [ID!]!) {
  deleteContacts(input: $input) {
    success
  }
}
    `;
export type DeleteContactsMutationFn = Apollo.MutationFunction<DeleteContactsMutation, DeleteContactsMutationVariables>;

/**
 * __useDeleteContactsMutation__
 *
 * To run a mutation, you first call `useDeleteContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactsMutation, { data, loading, error }] = useDeleteContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactsMutation, DeleteContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactsMutation, DeleteContactsMutationVariables>(DeleteContactsDocument, options);
      }
export type DeleteContactsMutationHookResult = ReturnType<typeof useDeleteContactsMutation>;
export type DeleteContactsMutationResult = Apollo.MutationResult<DeleteContactsMutation>;
export type DeleteContactsMutationOptions = Apollo.BaseMutationOptions<DeleteContactsMutation, DeleteContactsMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ImportContactsFromCsvDocument = gql`
    mutation ImportContactsFromCsv($input: ImportContactsFromCsvInput) {
  importContactsFromCsv(input: $input) {
    createdAt
    createdById
    email
    firstName
    id
    lastName
    mobileNumber
    updatedAt
  }
}
    `;
export type ImportContactsFromCsvMutationFn = Apollo.MutationFunction<ImportContactsFromCsvMutation, ImportContactsFromCsvMutationVariables>;

/**
 * __useImportContactsFromCsvMutation__
 *
 * To run a mutation, you first call `useImportContactsFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportContactsFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importContactsFromCsvMutation, { data, loading, error }] = useImportContactsFromCsvMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportContactsFromCsvMutation(baseOptions?: Apollo.MutationHookOptions<ImportContactsFromCsvMutation, ImportContactsFromCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportContactsFromCsvMutation, ImportContactsFromCsvMutationVariables>(ImportContactsFromCsvDocument, options);
      }
export type ImportContactsFromCsvMutationHookResult = ReturnType<typeof useImportContactsFromCsvMutation>;
export type ImportContactsFromCsvMutationResult = Apollo.MutationResult<ImportContactsFromCsvMutation>;
export type ImportContactsFromCsvMutationOptions = Apollo.BaseMutationOptions<ImportContactsFromCsvMutation, ImportContactsFromCsvMutationVariables>;
export const LoginEmailDocument = gql`
    mutation LoginEmail($email: String!, $password: String!) {
  loginEmail(email: $email, password: $password) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      approved
      blocked
      createdAt
      deletedAt
      email
      emailVerified
      firstName
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      lastName
      mobileNumber
      organizationNumber
      quota
      remainingSMSQuota
      role
      senderName
      updatedAt
    }
    userId
  }
}
    `;
export type LoginEmailMutationFn = Apollo.MutationFunction<LoginEmailMutation, LoginEmailMutationVariables>;

/**
 * __useLoginEmailMutation__
 *
 * To run a mutation, you first call `useLoginEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginEmailMutation, { data, loading, error }] = useLoginEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginEmailMutation(baseOptions?: Apollo.MutationHookOptions<LoginEmailMutation, LoginEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginEmailMutation, LoginEmailMutationVariables>(LoginEmailDocument, options);
      }
export type LoginEmailMutationHookResult = ReturnType<typeof useLoginEmailMutation>;
export type LoginEmailMutationResult = Apollo.MutationResult<LoginEmailMutation>;
export type LoginEmailMutationOptions = Apollo.BaseMutationOptions<LoginEmailMutation, LoginEmailMutationVariables>;
export const ReSendUserInviteDocument = gql`
    mutation ReSendUserInvite($id: ID!) {
  reSendUserInvite(id: $id) {
    success
  }
}
    `;
export type ReSendUserInviteMutationFn = Apollo.MutationFunction<ReSendUserInviteMutation, ReSendUserInviteMutationVariables>;

/**
 * __useReSendUserInviteMutation__
 *
 * To run a mutation, you first call `useReSendUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReSendUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reSendUserInviteMutation, { data, loading, error }] = useReSendUserInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReSendUserInviteMutation(baseOptions?: Apollo.MutationHookOptions<ReSendUserInviteMutation, ReSendUserInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReSendUserInviteMutation, ReSendUserInviteMutationVariables>(ReSendUserInviteDocument, options);
      }
export type ReSendUserInviteMutationHookResult = ReturnType<typeof useReSendUserInviteMutation>;
export type ReSendUserInviteMutationResult = Apollo.MutationResult<ReSendUserInviteMutation>;
export type ReSendUserInviteMutationOptions = Apollo.BaseMutationOptions<ReSendUserInviteMutation, ReSendUserInviteMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($token: String!) {
  refreshToken(token: $token) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      approved
      blocked
      createdAt
      deletedAt
      email
      emailVerified
      firstName
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      lastName
      mobileNumber
      organizationNumber
      quota
      remainingSMSQuota
      role
      senderName
      updatedAt
    }
    userId
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const RemoveContactListContactsDocument = gql`
    mutation RemoveContactListContacts($input: AddRemoveContactListContactsInput!) {
  removeContactListContacts(input: $input) {
    contactCount
    createdAt
    createdById
    id
    name
    updatedAt
  }
}
    `;
export type RemoveContactListContactsMutationFn = Apollo.MutationFunction<RemoveContactListContactsMutation, RemoveContactListContactsMutationVariables>;

/**
 * __useRemoveContactListContactsMutation__
 *
 * To run a mutation, you first call `useRemoveContactListContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactListContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactListContactsMutation, { data, loading, error }] = useRemoveContactListContactsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveContactListContactsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactListContactsMutation, RemoveContactListContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveContactListContactsMutation, RemoveContactListContactsMutationVariables>(RemoveContactListContactsDocument, options);
      }
export type RemoveContactListContactsMutationHookResult = ReturnType<typeof useRemoveContactListContactsMutation>;
export type RemoveContactListContactsMutationResult = Apollo.MutationResult<RemoveContactListContactsMutation>;
export type RemoveContactListContactsMutationOptions = Apollo.BaseMutationOptions<RemoveContactListContactsMutation, RemoveContactListContactsMutationVariables>;
export const ResendVerifyEmailDocument = gql`
    mutation ResendVerifyEmail($id: ID!) {
  resendVerifyEmail(id: $id) {
    approved
    blocked
    createdAt
    deletedAt
    email
    emailVerified
    firstName
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    lastName
    mobileNumber
    organizationNumber
    quota
    remainingSMSQuota
    role
    senderName
    updatedAt
  }
}
    `;
export type ResendVerifyEmailMutationFn = Apollo.MutationFunction<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>;

/**
 * __useResendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useResendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerifyEmailMutation, { data, loading, error }] = useResendVerifyEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>(ResendVerifyEmailDocument, options);
      }
export type ResendVerifyEmailMutationHookResult = ReturnType<typeof useResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationResult = Apollo.MutationResult<ResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>;
export const ResetPasswordFinalizeDocument = gql`
    mutation ResetPasswordFinalize($input: ResetPasswordFinalizeInput!) {
  resetPasswordFinalize(input: $input) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      approved
      blocked
      createdAt
      deletedAt
      email
      emailVerified
      firstName
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      lastName
      mobileNumber
      organizationNumber
      quota
      remainingSMSQuota
      role
      senderName
      updatedAt
    }
    userId
  }
}
    `;
export type ResetPasswordFinalizeMutationFn = Apollo.MutationFunction<ResetPasswordFinalizeMutation, ResetPasswordFinalizeMutationVariables>;

/**
 * __useResetPasswordFinalizeMutation__
 *
 * To run a mutation, you first call `useResetPasswordFinalizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordFinalizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordFinalizeMutation, { data, loading, error }] = useResetPasswordFinalizeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordFinalizeMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordFinalizeMutation, ResetPasswordFinalizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordFinalizeMutation, ResetPasswordFinalizeMutationVariables>(ResetPasswordFinalizeDocument, options);
      }
export type ResetPasswordFinalizeMutationHookResult = ReturnType<typeof useResetPasswordFinalizeMutation>;
export type ResetPasswordFinalizeMutationResult = Apollo.MutationResult<ResetPasswordFinalizeMutation>;
export type ResetPasswordFinalizeMutationOptions = Apollo.BaseMutationOptions<ResetPasswordFinalizeMutation, ResetPasswordFinalizeMutationVariables>;
export const ResetPasswordInitiateDocument = gql`
    mutation ResetPasswordInitiate($email: String!) {
  resetPasswordInitiate(email: $email) {
    success
  }
}
    `;
export type ResetPasswordInitiateMutationFn = Apollo.MutationFunction<ResetPasswordInitiateMutation, ResetPasswordInitiateMutationVariables>;

/**
 * __useResetPasswordInitiateMutation__
 *
 * To run a mutation, you first call `useResetPasswordInitiateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordInitiateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordInitiateMutation, { data, loading, error }] = useResetPasswordInitiateMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordInitiateMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordInitiateMutation, ResetPasswordInitiateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordInitiateMutation, ResetPasswordInitiateMutationVariables>(ResetPasswordInitiateDocument, options);
      }
export type ResetPasswordInitiateMutationHookResult = ReturnType<typeof useResetPasswordInitiateMutation>;
export type ResetPasswordInitiateMutationResult = Apollo.MutationResult<ResetPasswordInitiateMutation>;
export type ResetPasswordInitiateMutationOptions = Apollo.BaseMutationOptions<ResetPasswordInitiateMutation, ResetPasswordInitiateMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($input: SendMessageInput!) {
  sendMessage(input: $input) {
    completedAt
    createdAt
    id
    status
    type
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const SendMessageToContactListDocument = gql`
    mutation SendMessageToContactList($input: SendContactListMessageInput!) {
  sendMessageToContactList(input: $input) {
    completedAt
    createdAt
    id
    status
    type
  }
}
    `;
export type SendMessageToContactListMutationFn = Apollo.MutationFunction<SendMessageToContactListMutation, SendMessageToContactListMutationVariables>;

/**
 * __useSendMessageToContactListMutation__
 *
 * To run a mutation, you first call `useSendMessageToContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToContactListMutation, { data, loading, error }] = useSendMessageToContactListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageToContactListMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageToContactListMutation, SendMessageToContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageToContactListMutation, SendMessageToContactListMutationVariables>(SendMessageToContactListDocument, options);
      }
export type SendMessageToContactListMutationHookResult = ReturnType<typeof useSendMessageToContactListMutation>;
export type SendMessageToContactListMutationResult = Apollo.MutationResult<SendMessageToContactListMutation>;
export type SendMessageToContactListMutationOptions = Apollo.BaseMutationOptions<SendMessageToContactListMutation, SendMessageToContactListMutationVariables>;
export const SetUserQuotaDocument = gql`
    mutation SetUserQuota($quota: Uint32!, $userId: ID!) {
  setUserQuota(quota: $quota, userId: $userId) {
    approved
    blocked
    createdAt
    deletedAt
    email
    emailVerified
    firstName
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    lastName
    mobileNumber
    organizationNumber
    quota
    remainingSMSQuota
    role
    senderName
    updatedAt
  }
}
    `;
export type SetUserQuotaMutationFn = Apollo.MutationFunction<SetUserQuotaMutation, SetUserQuotaMutationVariables>;

/**
 * __useSetUserQuotaMutation__
 *
 * To run a mutation, you first call `useSetUserQuotaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserQuotaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserQuotaMutation, { data, loading, error }] = useSetUserQuotaMutation({
 *   variables: {
 *      quota: // value for 'quota'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetUserQuotaMutation(baseOptions?: Apollo.MutationHookOptions<SetUserQuotaMutation, SetUserQuotaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserQuotaMutation, SetUserQuotaMutationVariables>(SetUserQuotaDocument, options);
      }
export type SetUserQuotaMutationHookResult = ReturnType<typeof useSetUserQuotaMutation>;
export type SetUserQuotaMutationResult = Apollo.MutationResult<SetUserQuotaMutation>;
export type SetUserQuotaMutationOptions = Apollo.BaseMutationOptions<SetUserQuotaMutation, SetUserQuotaMutationVariables>;
export const ToggleBlockUserDocument = gql`
    mutation ToggleBlockUser($id: ID!) {
  toggleBlockUser(id: $id) {
    approved
    blocked
    createdAt
    deletedAt
    email
    emailVerified
    firstName
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    lastName
    mobileNumber
    organizationNumber
    quota
    remainingSMSQuota
    role
    senderName
    updatedAt
  }
}
    `;
export type ToggleBlockUserMutationFn = Apollo.MutationFunction<ToggleBlockUserMutation, ToggleBlockUserMutationVariables>;

/**
 * __useToggleBlockUserMutation__
 *
 * To run a mutation, you first call `useToggleBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBlockUserMutation, { data, loading, error }] = useToggleBlockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<ToggleBlockUserMutation, ToggleBlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleBlockUserMutation, ToggleBlockUserMutationVariables>(ToggleBlockUserDocument, options);
      }
export type ToggleBlockUserMutationHookResult = ReturnType<typeof useToggleBlockUserMutation>;
export type ToggleBlockUserMutationResult = Apollo.MutationResult<ToggleBlockUserMutation>;
export type ToggleBlockUserMutationOptions = Apollo.BaseMutationOptions<ToggleBlockUserMutation, ToggleBlockUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    approved
    blocked
    createdAt
    deletedAt
    email
    emailVerified
    firstName
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    lastName
    mobileNumber
    organizationNumber
    quota
    remainingSMSQuota
    role
    senderName
    updatedAt
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpsertContactDocument = gql`
    mutation UpsertContact($input: UpsertContactInput!) {
  upsertContact(input: $input) {
    createdAt
    createdById
    email
    firstName
    id
    lastName
    mobileNumber
    updatedAt
  }
}
    `;
export type UpsertContactMutationFn = Apollo.MutationFunction<UpsertContactMutation, UpsertContactMutationVariables>;

/**
 * __useUpsertContactMutation__
 *
 * To run a mutation, you first call `useUpsertContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContactMutation, { data, loading, error }] = useUpsertContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertContactMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContactMutation, UpsertContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContactMutation, UpsertContactMutationVariables>(UpsertContactDocument, options);
      }
export type UpsertContactMutationHookResult = ReturnType<typeof useUpsertContactMutation>;
export type UpsertContactMutationResult = Apollo.MutationResult<UpsertContactMutation>;
export type UpsertContactMutationOptions = Apollo.BaseMutationOptions<UpsertContactMutation, UpsertContactMutationVariables>;
export const UpsertContactListDocument = gql`
    mutation UpsertContactList($input: UpsertContactListInput!) {
  upsertContactList(input: $input) {
    contactCount
    createdAt
    createdById
    id
    name
    updatedAt
  }
}
    `;
export type UpsertContactListMutationFn = Apollo.MutationFunction<UpsertContactListMutation, UpsertContactListMutationVariables>;

/**
 * __useUpsertContactListMutation__
 *
 * To run a mutation, you first call `useUpsertContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertContactListMutation, { data, loading, error }] = useUpsertContactListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertContactListMutation(baseOptions?: Apollo.MutationHookOptions<UpsertContactListMutation, UpsertContactListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertContactListMutation, UpsertContactListMutationVariables>(UpsertContactListDocument, options);
      }
export type UpsertContactListMutationHookResult = ReturnType<typeof useUpsertContactListMutation>;
export type UpsertContactListMutationResult = Apollo.MutationResult<UpsertContactListMutation>;
export type UpsertContactListMutationOptions = Apollo.BaseMutationOptions<UpsertContactListMutation, UpsertContactListMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($email: String!, $nonce: String!) {
  verifyEmail(email: $email, nonce: $nonce) {
    approved
    blocked
    createdAt
    deletedAt
    email
    emailVerified
    firstName
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    lastName
    mobileNumber
    organizationNumber
    quota
    remainingSMSQuota
    role
    senderName
    updatedAt
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      nonce: // value for 'nonce'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;