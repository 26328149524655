import { useCallback, useContext, ReactNode } from 'react';

import classNames from 'classnames';
import remove from 'lodash/remove';

import { Icon, IconProps } from 'components/ui/general';
import { AccordionSelectors } from 'consts/cypress';
import { AccordionContext } from 'context/accordion';

import styles from '../Accordion.module.scss';

interface AccordionLabelProps {
  children: ReactNode;
  className?: string;
}

export const AccordionLabel = ({
  children,
  className,
  ...props
}: AccordionLabelProps) => {
  const [activeItems, setActiveItems] = useContext(AccordionContext);
  const { id, multiple, isActive, disabled, iconClosed, iconOpened } =
    props as {
      id: string;
      multiple?: boolean;
      isActive: boolean;
      disabled?: boolean;
      iconClosed?: IconProps;
      iconOpened?: IconProps;
    };

  const updateActiveItems = useCallback(() => {
    if (multiple) {
      const collectActiveItems = isActive
        ? remove(activeItems, (activeId) => activeId !== id)
        : [...activeItems, id];
      setActiveItems(collectActiveItems);
      return;
    }

    setActiveItems(isActive ? [] : [id]);
  }, [isActive, multiple, activeItems, id, setActiveItems]);

  return (
    <button
      type="button"
      className={classNames(styles.label, className, {
        [styles.isActive]: isActive
      })}
      onClick={updateActiveItems}
      disabled={disabled}
      data-cy={AccordionSelectors.Label}
    >
      <div className={styles.labelHolder}>{children}</div>
      <div className={styles.iconHolder}>
        <Icon
          name={iconClosed?.name || 'plus'}
          font={iconClosed?.font}
          className={styles.iconClosed}
        />
        <Icon
          name={iconOpened?.name || 'minus'}
          font={iconOpened?.font}
          className={styles.iconOpened}
        />
      </div>
    </button>
  );
};
