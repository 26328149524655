import {
  ChangeEvent,
  useState,
  useCallback,
  MutableRefObject,
  useMemo,
  ReactNode
} from 'react';

import classNames from 'classnames';
import { RegisterOptions, UseFormRegister, FieldErrors } from 'react-hook-form';

import { Error } from 'components/ui/forms';
import { FileSelectors } from 'consts/cypress';

import styles from './File.module.scss';

export interface FileProps {
  name: string;
  accept?: string;
  capture?: 'user' | 'environment';
  multiple?: boolean;
  className?: string;
  ariaLabel?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  validation?: RegisterOptions;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: FieldErrors;
  fileRef?: MutableRefObject<HTMLInputElement | null>;
  children?: ReactNode;
  showFileList?: boolean;
  fullWidth?: boolean;
}

export const File = ({
  name,
  accept,
  capture,
  multiple,
  className,
  ariaLabel,
  disabled,
  onChange,
  onBlur,
  register,
  validation,
  error,
  fileRef,
  children,
  showFileList,
  fullWidth
}: FileProps) => {
  const [fileList, setFileList] = useState<string[]>([]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files }
      } = event;

      if (onChange) onChange(event);

      if (files) {
        setFileList(Array.from(files).map((file) => file.name));
      }
    },
    [onChange]
  );

  const getValidation = useMemo(() => {
    return !disabled ? validation : {};
  }, [disabled, validation]);

  const registerHolder = useMemo(
    () => register(name, getValidation),
    [name, register, getValidation]
  );

  return (
    <>
      <div
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth
        })}
        data-cy={FileSelectors.Root}
      >
        <label className={styles.label}>
          <input
            type="file"
            accept={accept}
            capture={capture}
            multiple={multiple}
            disabled={disabled}
            aria-label={ariaLabel}
            className={styles.input}
            data-cy={FileSelectors.Input}
            {...registerHolder}
            ref={(event) => {
              if (fileRef) fileRef.current = event;
              registerHolder.ref(event);
            }}
            onChange={(event) => {
              registerHolder.onChange(event);
              handleOnChange(event);
            }}
            onBlur={(event) => {
              registerHolder.onBlur(event);
              onBlur?.(event);
            }}
          />
          {children}
        </label>
        {showFileList && (
          <div>
            {fileList.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index}>{file}</p>
            ))}
          </div>
        )}
      </div>
      <Error error={error} />
    </>
  );
};
