export enum Emails {
  Admin = 'cypress.admin@interactivesolutions.se'
}

export enum Passwords {
  Admin = 'helloWorld'
}

/**
 * When selecting elements for Cypress you should use data-cy="". When defining
 * and selecting elements you should use this constants file. Try to follow this
 * naming convention:
 *
 * export enum <ComponentName>Selectors {
 *   Heading = '<component-name>-heading',
 *   Paragraph = '<component-name>-paragraph'
 * }
 *
 * So let's say you have a Button component where you want to select the content
 * and the spinner, that would result in:
 *
 * export enum ButtonSelectors {
 *   Content = 'button-content',
 *   Spinner = 'button-spinner'
 * }
 */

export enum ButtonSelectors {
  Root = 'button-root'
}

export enum LoginSelectors {
  Email = 'login-email',
  Password = 'login-password',
  Form = 'login-form'
}

export enum TableSelectors {
  Root = 'table-root',
  Search = 'table-search',
  Pagination = 'table-pagination',
  Head = 'table-head',
  HeadTr = 'table-head-tr',
  HeadTh = 'table-head-th',
  Body = 'table-body',
  BodyTr = 'table-body-tr',
  BodyTd = 'table-body-td'
}

export enum CheckboxSelectors {
  Root = 'checkbox-root'
}

export enum ErrorSelectors {
  Root = 'error-root'
}

export enum FileSelectors {
  Root = 'file-root',
  Input = 'file-input'
}

export enum RadioSelectors {
  Root = 'radio-root'
}

export enum SelectSelectors {
  Root = 'select-root'
}

export enum SwitchSelectors {
  Root = 'switch-root',
  Input = 'switch-input',
  Controlled = 'switch-controlled',
  ControlledInput = 'switch-controlled-input'
}

export enum TextSelectors {
  Root = 'text-root',
  Input = 'text-input'
}

export enum TextareaSelectors {
  Root = 'textarea-root',
  Textarea = 'textarea-textarea'
}

export enum GdprConsentSelectors {
  Root = 'gdpr-consent-root'
}

export enum ModalSelectors {
  Root = 'modal-root'
}

export enum ToastManagerSelectors {
  Toasts = 'toast-manager-toasts'
}

export enum DrawerSelectors {
  Root = 'drawer-root'
}

export enum AccordionSelectors {
  Root = 'accordion-root',
  Content = 'accordion-content',
  Item = 'accordion-item',
  Label = 'accordion-label'
}

export enum TooltipSelectors {
  Trigger = 'tooltip-trigger',
  Tooltip = 'tooltip-tooltip'
}

export enum TabsSelectors {
  Root = 'tabs-root',
  Label = 'tabs-label',
  Content = 'tabs-content'
}

export enum ImageSelectors {
  Root = 'image-root',
  Spinner = 'image-spinner',
  Image = 'image-image'
}

export enum MenuSelectors {
  Root = 'menu-root',
  Trigger = 'menu-trigger',
  Menu = 'menu-menu',
  Item = 'menu-item'
}

export enum CardSelectors {
  Root = 'card-root'
}
