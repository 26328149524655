import { ComponentProps } from 'react';

import { components } from 'react-select';

import { Spinner } from 'components/ui/general';

export const LoadingMessage = (
  props: ComponentProps<typeof components.LoadingMessage>
) => {
  return (
    <components.LoadingMessage {...props}>
      <Spinner visible size="sm" />
    </components.LoadingMessage>
  );
};
