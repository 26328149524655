import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.common,
  ...shared.form,
  createTitle: {
    id: 'ContactModal.createTitle',
    description: 'Table header title',
    defaultMessage: 'Lägg till ny kontakt'
  },
  editTitle: {
    id: 'ContactModal.editTitle',
    description: 'Table header title',
    defaultMessage: 'Redigera kontakt'
  },
  createContactModalButtonText: {
    id: 'ContactModal.createContactModalButtonText',
    description: 'Create contact modal button',
    defaultMessage: 'Skapa kontakt'
  },
  editContactModalButtonText: {
    id: 'ContactModal.editContactModalButtonText',
    description: 'Edit contact modal button',
    defaultMessage: 'Redigera kontakt'
  },
  createContactError: {
    id: 'ContactModal.createContactError',
    description: 'create contact error message',
    defaultMessage: 'Något gick fel när kontakten skulle skapas, felmeddelande:'
  },
  addContactToListError: {
    id: 'ContactModal.addContactToListError',
    description: 'add contact to list error message',
    defaultMessage:
      'Något gick fel när kontakten skulle läggas till i kontaktlistan, felmeddelande:'
  },
  createContactSuccess: {
    id: 'ContactModal.createContactSuccess',
    description: 'create contact success',
    defaultMessage: 'Kontakt skapades'
  }
});
