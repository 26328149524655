import { useCallback, useEffect, useRef, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { AlertCard } from 'components/ui/general';
import { Durations, Easings } from 'consts/transition';
import { useAuth, useToast } from 'hooks';
import { removeJobId, removeJobIds, selectJobIds } from 'redux/jobs';
import { Job, JobStatus, Scalars, useJobsLazyQuery } from 'types/graphql';

import { texts } from './JobsManager.text';

import styles from '../ToastManager/ToastManager.module.scss';

interface JobProps {
  [key: Scalars['ID']]: Job;
}

const Manager = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { formatMessage } = useIntl();

  const selectorJobIds = useSelector(selectJobIds);
  const timerIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [fetch, { data, error }] = useJobsLazyQuery({
    fetchPolicy: 'network-only'
  });
  const jobsData = data?.jobs.edges;
  const [jobs, setJobs] = useState<JobProps>({});

  const addJob = useCallback(
    (j: Job) => {
      const newJobs: JobProps = Object.entries(jobs).reduce(
        (acc, [key, value]) => {
          return { ...acc, ...{ [key]: value } };
        },
        {}
      );

      newJobs[j.id] = j;
      setJobs(newJobs);
    },
    [jobs]
  );

  const removeJobs = useCallback(
    (ids: Scalars['ID'][]) => {
      const newJobs: JobProps = Object.entries(jobs).reduce(
        (acc, [key, value]) => {
          return { ...acc, ...{ [key]: value } };
        },
        {}
      );
      ids.forEach((id) => {
        delete newJobs[id];
      });

      setJobs(newJobs);
    },
    [jobs]
  );

  const removeJobDelay = useCallback(
    (ids: Scalars['ID'][]) => {
      setTimeout(() => removeJobs(ids), 5000);
    },
    [removeJobs]
  );

  useEffect(() => {
    const pollingCallback = () => {
      fetch({
        variables: {
          filter: {
            ids: selectorJobIds
          }
        }
      });
    };

    const startPolling = () => {
      pollingCallback();
      timerIdRef.current = setInterval(pollingCallback, 3000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current as NodeJS.Timeout);
    };

    if (selectorJobIds.length) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [fetch, selectorJobIds]);

  useEffect(() => {
    const ids = Object.keys(jobs);
    jobsData?.forEach((j) => {
      if (!ids.includes(j.id as string) && selectorJobIds.includes(j.id)) {
        return addJob(j);
      }
      if (jobs[j.id]) {
        if (j.status !== jobs[j.id].status) {
          jobs[j.id] = j;
        }
      }
    });
  }, [addJob, dispatch, jobs, jobsData, selectorJobIds]);

  useEffect(() => {
    const ids: Scalars['ID'][] = [];
    jobsData?.forEach((j) => {
      if (jobs[j.id]) {
        if (j.status === JobStatus.Completed) {
          dispatch(removeJobId(j.id));
          ids.push(j.id);
        }
      }
    });
    if (ids.length) {
      removeJobDelay(ids);
    }
  }, [dispatch, jobs, jobsData, removeJobDelay]);

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.failedTitle),
        message: formatMessage(texts.pollError),
        type: 'error'
      });
      dispatch(removeJobIds(selectorJobIds));
    }
  }, [addToast, dispatch, error, formatMessage, selectorJobIds]);

  const getType = useCallback((status: JobStatus) => {
    if (status === JobStatus.Started) return 'info';
    if (status === JobStatus.Completed) return 'success';
    if (status === JobStatus.Failed) return 'error';
  }, []);

  const getText = useCallback(
    (status: JobStatus) => {
      if (status === JobStatus.Started)
        return {
          title: formatMessage(texts.sendingTitle),
          messsage: formatMessage(texts.sendingMessage)
        };
      if (status === JobStatus.Completed)
        return {
          title: formatMessage(texts.sentTitle),
          messsage: formatMessage(texts.sentMessage)
        };
      if (status === JobStatus.Failed)
        return {
          title: formatMessage(texts.failedTitle),
          messsage: formatMessage(texts.failedMessage)
        };
    },
    [formatMessage]
  );

  return (
    <AnimatePresence>
      {Object.keys(jobs).map((jobId) => {
        const { id, status } = jobs[jobId];
        return (
          <motion.div
            key={id}
            className={styles.toast}
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: 'auto'
            }}
            transition={{
              duration: Durations.Fast,
              ease: Easings.InOut
            }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className={styles.container}>
              <AlertCard
                title={getText(status)?.title}
                message={getText(status)?.messsage}
                type={getType(status)}
                loading={status === JobStatus.Started}
                buttonRight={{
                  onClick: () => removeJobs([id])
                }}
              />
            </div>
          </motion.div>
        );
      })}
    </AnimatePresence>
  );
};

export const JobsManager = () => {
  const { loggedIn } = useAuth();

  if (!loggedIn) return null;
  return <Manager />;
};
