import { ReactNode } from 'react';

import classNames from 'classnames';

import { StellarTechLogo } from 'assets/icons';
import { Button, ButtonProps, Gutter } from 'components/ui/general';
import { capitalizeFirstLetter } from 'utils';

import styles from './Empty.module.scss';

export interface EmptyProps {
  title: ReactNode;
  message?: string;
  className?: string;
  button?: ButtonProps;
}

export const Empty = ({ title, message, className, button }: EmptyProps) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.inner}>
        <Gutter gutter={{ bottom: 2 }}>
          <Gutter.Item>
            <Gutter gutter={{ bottom: 2 }}>
              <Gutter.Item>
                <div className={styles.icon}>
                  <StellarTechLogo className={styles.logo} />
                </div>
              </Gutter.Item>
              <Gutter.Item>
                <div className={styles.title}>{title}</div>
              </Gutter.Item>
            </Gutter>
            {!!message && (
              <div className={styles.message}>
                {capitalizeFirstLetter(message)}
              </div>
            )}
          </Gutter.Item>
          {!!button && (
            <Gutter.Item>
              <Button {...button} />
            </Gutter.Item>
          )}
        </Gutter>
      </div>
    </div>
  );
};
