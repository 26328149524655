import { MouseEventHandler } from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/ui/general';
import { TableSelectors } from 'consts/cypress';

import { texts } from './Pagination.text';

import styles from './Pagination.module.scss';

interface PaginationProps {
  classNamePagination?: string;
  gotoPage: (pageIndex: number) => void;
  canPreviousPage: boolean;
  previousPage: MouseEventHandler;
  nextPage: MouseEventHandler;
  canNextPage: boolean;
  pageCount: number;
  pageIndex: number;
  pageOptions: number[];
}

export const Pagination = ({
  classNamePagination,
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageOptions
}: PaginationProps) => {
  return (
    <div
      className={classNames(styles.root, classNamePagination)}
      data-cy={TableSelectors.Pagination}
    >
      <div className={styles.navigation}>
        <button
          type="button"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className={styles.navigationButton}
        >
          <Icon name="chevron-double-left" />
        </button>
        <button
          type="button"
          onClick={previousPage}
          disabled={!canPreviousPage}
          className={styles.navigationButton}
        >
          <Icon name="chevron-left" />
        </button>
        <div className={styles.navigationText}>
          <FormattedMessage {...texts.page} /> {pageIndex + 1}{' '}
          <FormattedMessage {...texts.of} /> {pageOptions.length}
        </div>
        <button
          type="button"
          onClick={nextPage}
          disabled={!canNextPage}
          className={styles.navigationButton}
        >
          <Icon name="chevron-right" />
        </button>
        <button
          type="button"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className={styles.navigationButton}
        >
          <Icon name="chevron-double-right" />
        </button>
      </div>
    </div>
  );
};
