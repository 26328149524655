import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo
} from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { TabsSelectors } from 'consts/cypress';
import { QueryParameters } from 'consts/tabs';
import { useToast, useUrlQuery } from 'hooks';
import {
  Contact,
  ContactList,
  useContactListsLazyQuery,
  useContactsLazyQuery
} from 'types/graphql';

import { texts } from './SendMessageTabs.text';

import styles from './SendMessageTabs.module.scss';

export interface SendMessageTabsProps {
  activePaneId?: string;
  className?: string;
  searchTerm?: string;
  setReceivers: (nextReceivers: Contact | ContactList | null) => void;
  isTabsOpen: boolean;
  setIsTabsOpen: Dispatch<SetStateAction<boolean>>;
  isMessageSent: boolean;
}

export const SendMessageTabs = ({
  activePaneId,
  className,
  searchTerm,
  setReceivers,
  isTabsOpen,
  setIsTabsOpen,
  isMessageSent
}: SendMessageTabsProps) => {
  const [fetchContacts, { data: contactsData, error: contactsError }] =
    useContactsLazyQuery();

  const [
    fetchContactLists,
    { data: contactListsData, error: contactListsError }
  ] = useContactListsLazyQuery();

  const { addToast } = useToast();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (contactsError || contactListsError) {
      addToast({
        title: formatMessage(texts.sendMessageError),
        type: 'error'
      });
    }
  }, [contactsError, addToast, contactListsError, formatMessage]);

  useEffect(() => {
    if (isMessageSent) return;
    if (!searchTerm || searchTerm === '') {
      setReceivers(null);
      setIsTabsOpen(false);
    }
  }, [isMessageSent, searchTerm, setIsTabsOpen, setReceivers]);

  const renderContacts = useCallback(() => {
    return contactsData?.contacts.edges.map((contact) => {
      return (
        <div
          role="button"
          onKeyUp={() => {}}
          tabIndex={0}
          key={contact.id}
          className={styles.listItem}
          onClick={() => {
            setReceivers(contact);
            setIsTabsOpen(false);
          }}
        >
          <div className={styles.contactBadge}>
            {contact.firstName[0]}
            {contact.lastName[0]}
          </div>
          <div className={styles.contactInfo}>
            <div className={styles.contactName}>
              {contact.firstName} {contact.lastName}
            </div>
            <div>
              {contact.mobileNumber}
              {contact.email && contact.mobileNumber
                ? `• ${contact.email}`
                : contact.email ?? null}
            </div>
          </div>
        </div>
      );
    });
  }, [contactsData?.contacts.edges, setIsTabsOpen, setReceivers]);

  const getInitials = useCallback((name: string) => {
    if (name) {
      const nameArray = name.trim().toUpperCase().split(/\s/);
      return nameArray.length === 1
        ? nameArray[0].substring(0, 2)
        : nameArray[0][0] + nameArray[1][0];
    }
  }, []);

  const renderContactLists = useCallback(() => {
    return contactListsData?.contactLists.edges.map((contactList) => {
      return (
        <div
          role="button"
          onKeyUp={() => {}}
          tabIndex={0}
          key={contactList.id}
          className={styles.listItem}
          onClick={() => {
            setReceivers(contactList);
            setIsTabsOpen(false);
          }}
        >
          <div className={styles.contactBadge}>
            {getInitials(contactList.name)}
          </div>
          <div>{contactList.name}</div>
        </div>
      );
    });
  }, [
    contactListsData?.contactLists.edges,
    getInitials,
    setIsTabsOpen,
    setReceivers
  ]);

  const getAllContacts = useCallback(() => {
    return (
      <div>
        {renderContacts()} {renderContactLists()}
      </div>
    );
  }, [renderContacts, renderContactLists]);

  const panes = useMemo(
    () => [
      {
        id: '1',
        label: formatMessage(texts.tabsAll),
        content:
          searchTerm === '' ? null : (
            <div className={styles.contactsMain}>{getAllContacts()}</div>
          )
      },
      {
        id: '2',
        label: formatMessage(texts.tabsContacts),
        content:
          searchTerm === '' ? null : (
            <div className={styles.contactsMain}>{renderContacts()}</div>
          )
      },
      {
        id: '3',
        label: formatMessage(texts.tabsLists),
        content:
          searchTerm === '' ? null : (
            <div className={styles.contactsMain}>{renderContactLists()}</div>
          )
      }
    ],
    [
      formatMessage,
      getAllContacts,
      renderContactLists,
      renderContacts,
      searchTerm
    ]
  );

  const { get, set } = useUrlQuery();
  const getActivePaneId = useMemo(
    () => get(QueryParameters.ActivePaneId) || activePaneId || panes[0].id,
    [activePaneId, get, panes]
  );

  useEffect(() => {
    if (getActivePaneId === '1' || getActivePaneId === '2') {
      fetchContacts({
        variables: {
          filter: {
            ...(searchTerm ? { searchTerm } : {})
          }
        }
      });
    }
    if (getActivePaneId === '1' || getActivePaneId === '3') {
      fetchContactLists({
        variables: {
          filter: {
            ...(searchTerm ? { searchTerm } : {})
          }
        }
      });
    }
  }, [fetchContactLists, fetchContacts, getActivePaneId, searchTerm]);

  const renderNavigation = useCallback(() => {
    return panes.map(({ id, label }) => (
      <button
        key={id}
        type="button"
        onClick={() => set(QueryParameters.ActivePaneId, id)}
        className={classNames(styles.button, {
          [styles.active]: getActivePaneId === id
        })}
        data-cy={TabsSelectors.Label}
      >
        {label}
      </button>
    ));
  }, [getActivePaneId, panes, set]);

  const renderContent = useCallback(() => {
    return panes.map(({ id, content }) => {
      if (getActivePaneId === id && isTabsOpen) {
        return (
          <div key={id} data-cy={TabsSelectors.Content}>
            {content}
          </div>
        );
      }

      return null;
    });
  }, [getActivePaneId, isTabsOpen, panes]);

  return (
    <div className={className} data-cy={TabsSelectors.Root}>
      <nav className={styles.navigation}>
        <div className={styles.navigationInner}>{renderNavigation()}</div>
      </nav>
      {renderContent()}
    </div>
  );
};
