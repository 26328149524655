import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.common,
  ...shared.table,
  fetchError: {
    id: 'CustomersTable.fetchError',
    description: 'Fetch error toast title',
    defaultMessage: 'Kunde inte hämta användare'
  },
  toggleBlockError: {
    id: 'CustomersTable.toggleBlockError',
    description: 'Toggle block error toast title',
    defaultMessage: 'Kunde inte blockera användare'
  }
});
