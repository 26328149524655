import { motion, AnimatePresence } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';

import { Portal } from 'components/tools';
import { AlertCard } from 'components/ui/general';
import { JobsManager } from 'components/ui/modals';
import { ToastManagerSelectors } from 'consts/cypress';
import { Durations, Easings } from 'consts/transition';
import { selectToasts, deleteToast } from 'redux/toast';
import { capitalizeFirstLetter } from 'utils';

import styles from './ToastManager.module.scss';

export const ToastManager = () => {
  const dispatch = useDispatch();
  const selectorToasts = useSelector(selectToasts);

  return (
    <Portal selector="#toast">
      <div className={styles.toasts} data-cy={ToastManagerSelectors.Toasts}>
        <JobsManager />
        <AnimatePresence>
          {selectorToasts.map(({ id, type = 'light', title, message }) => {
            return (
              <motion.div
                key={id}
                className={styles.toast}
                initial={{ opacity: 0, height: 0 }}
                animate={{
                  opacity: 1,
                  height: 'auto'
                }}
                transition={{
                  duration: Durations.Fast,
                  ease: Easings.InOut
                }}
                exit={{ opacity: 0, height: 0 }}
              >
                <div className={styles.container}>
                  <AlertCard
                    title={title}
                    message={
                      message ? capitalizeFirstLetter(message) : undefined
                    }
                    type={type}
                    buttonRight={{
                      onClick: () => dispatch(deleteToast({ id }))
                    }}
                  />
                </div>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
    </Portal>
  );
};
