import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  sendingTitle: {
    id: 'JobsManager.sendingTitle',
    description: 'Sending title Toast',
    defaultMessage: 'Skickar'
  },
  sendingMessage: {
    id: 'JobsManager.sendingMessage',
    description: 'Sending message Toast',
    defaultMessage: 'Skickar sms...'
  },
  sentTitle: {
    id: 'JobsManager.sentTitle',
    description: 'Sent title Toast',
    defaultMessage: 'Skickat'
  },
  sentMessage: {
    id: 'JobsManager.sentMessage',
    description: 'Sent message Toast',
    defaultMessage: 'Utskick klart!'
  },
  failedTitle: {
    id: 'JobsManager.failedTitle',
    description: 'Failed title Toast',
    defaultMessage: 'Misslyckat'
  },
  failedMessage: {
    id: 'JobsManager.failedMessage',
    description: 'Failed message Toast',
    defaultMessage: 'Utskick Misslyckat'
  },
  pollError: {
    id: 'JobsManager.pollError',
    description: 'Poll error',
    defaultMessage: 'Kunde inte hämta utskicks-status'
  }
});
