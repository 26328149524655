import { lazy } from 'react';

import { backoffice } from 'routes/backoffice';
import { client } from 'routes/client';

export const routes = {
  backoffice,
  client,
  shared: {
    NotFound: lazy(() => import('./NotFound'))
  }
};
