import { FC, useCallback, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { LabeledText, Select } from 'components/ui/forms';
import { Button } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { useToast, useValidate } from 'hooks';
import {
  Contact,
  ContactList,
  ContactListsDocument,
  ContactsDocument,
  UpsertContactInput,
  useAddContactListContactsMutation,
  useUpsertContactMutation
} from 'types/graphql';

import { texts } from './ContactModal.text';

import styles from './ContactModal.module.scss';

interface ContactModalProps {
  visible: boolean;
  close: () => void;
  refetch: () => void;
  contactLists: ContactList[];
  editContact?: Contact;
}

export const ContactModal: FC<ContactModalProps> = ({
  visible,
  close,
  refetch,
  contactLists,
  editContact
}) => {
  const { isEmail, isIntPhoneNumber } = useValidate();

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues: {
      firstName: editContact?.firstName || '',
      lastName: editContact?.lastName || '',
      mobileNumber: editContact?.mobileNumber || '',
      email: editContact?.email
    }
  });

  useEffect(() => {
    if (editContact) {
      reset({
        firstName: editContact?.firstName,
        lastName: editContact?.lastName,
        mobileNumber: editContact?.mobileNumber,
        email: editContact?.email
      });
    }
  }, [editContact, reset]);
  const { formatMessage } = useIntl();
  const [createMutation, { error, loading }] = useUpsertContactMutation();
  const [addToListMutation, { error: listError, loading: listLoading }] =
    useAddContactListContactsMutation();

  const { addToast } = useToast();
  useEffect(() => {
    if (error) {
      addToast({
        title: `${formatMessage(texts.createContactError)} ${error}`,
        type: 'error'
      });
    }
    if (listError) {
      addToast({
        title: `${formatMessage(texts.addContactToListError)} ${error}`,
        type: 'error'
      });
    }
  }, [addToast, error, formatMessage, listError]);

  const onSubmit = useCallback(
    async ({
      firstName,
      lastName,
      mobileNumber,
      email,
      list
    }: UpsertContactInput & { list: { value: string } }) => {
      const { data: createData, errors: createError } = await createMutation({
        variables: {
          input: {
            id: editContact?.id,
            firstName,
            lastName,
            mobileNumber,
            email
          }
        },
        refetchQueries: [ContactsDocument, ContactListsDocument]
      });

      if (createError) return close();

      if (createData?.upsertContact.id && list?.value) {
        const { errors: addError } = await addToListMutation({
          variables: {
            input: {
              contactIds: [createData.upsertContact.id],
              contactListId: list.value
            }
          },
          refetchQueries: [ContactsDocument, ContactListsDocument]
        });

        if (addError) return close();
      }

      refetch();
      addToast({
        title: formatMessage(texts.createContactSuccess),
        type: 'success'
      });
      close();
    },
    [
      addToListMutation,
      addToast,
      close,
      createMutation,
      editContact?.id,
      formatMessage,
      refetch
    ]
  );

  const selectOptions = useMemo(() => {
    if (contactLists) {
      return contactLists.map((list) => ({
        label: list.name,
        value: list.id
      }));
    }
    return [];
  }, [contactLists]);

  useEffect(() => {
    if (isSubmitSuccessful) reset();
  }, [isSubmitSuccessful, reset]);

  if (!visible) return null;

  return (
    <Modal isOpen={visible} onClose={close}>
      <div className={styles.root}>
        <h5>
          {editContact ? (
            <FormattedMessage {...texts.editTitle} />
          ) : (
            <FormattedMessage {...texts.createTitle} />
          )}
        </h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputWrapper}>
            <LabeledText
              label={formatMessage(texts.firstName)}
              placeholder={formatMessage(texts.firstName)}
              fullWidth
              name="firstName"
              register={register}
              validation={{ required: true }}
              error={errors.firstName}
            />
          </div>
          <div className={styles.inputWrapper}>
            <LabeledText
              label={formatMessage(texts.lastName)}
              placeholder={formatMessage(texts.lastName)}
              fullWidth
              name="lastName"
              register={register}
              validation={{ required: true }}
              error={errors.lastName}
            />
          </div>
          <div className={styles.inputWrapper}>
            <LabeledText
              label={formatMessage(texts.mobileNumber)}
              placeholder={formatMessage(texts.mobileNumber)}
              fullWidth
              name="mobileNumber"
              register={register}
              validation={{
                required: true,
                validate: isIntPhoneNumber
              }}
              error={errors.mobileNumber}
            />
          </div>
          <div className={styles.inputWrapper}>
            <LabeledText
              label={formatMessage(texts.email)}
              placeholder={formatMessage(texts.email)}
              fullWidth
              name="email"
              register={register}
              validation={{
                validate: (value) => value.length && isEmail(value)
              }}
              error={errors.email}
            />
          </div>
          <div className={styles.marginBottom}>
            <Select
              fullWidth
              control={control}
              name="list"
              options={selectOptions || []}
              placeholder={formatMessage(texts.list)}
              size="lg"
            />
          </div>
          <div className={styles.modalButtonWrapper}>
            <Button
              size="xl"
              onClick={close}
              loading={loading || listLoading}
              color="secondary"
            >
              <FormattedMessage {...texts.cancel} />
            </Button>
            <Button
              size="xl"
              className={styles.buttonMargin}
              type="submit"
              loading={loading || listLoading}
            >
              {editContact ? (
                <FormattedMessage {...texts.editContactModalButtonText} />
              ) : (
                <FormattedMessage {...texts.createContactModalButtonText} />
              )}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
