import { useCallback, useEffect } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Text } from 'components/ui/forms';
import { TableSelectors } from 'consts/cypress';

import { texts } from './Search.text';

import styles from './Search.module.scss';

interface SearchProps {
  classNameSearch?: string;
  onWatch: (value: string) => void;
  watch: any;
  defaultValue?: string;
  rounded?: boolean;
  placeholder?: string;
  register: UseFormRegister<any>;
  onBlur?: () => void;
  onChange?: () => void;
  size?: 'md' | 'lg';
}

export const Search = ({
  classNameSearch,
  onWatch,
  watch,
  defaultValue,
  rounded = false,
  placeholder,
  register,
  onBlur,
  onChange,
  size
}: SearchProps) => {
  const { formatMessage } = useIntl();

  const watchSearchTerm = watch('searchTerm');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnWatch = useCallback(
    debounce((value: string) => {
      onWatch(value);
    }, 250),
    []
  );

  useEffect(() => {
    return () => debounceOnWatch.cancel();
  }, [debounceOnWatch]);

  useEffect(() => {
    debounceOnWatch(watchSearchTerm);
  }, [debounceOnWatch, watchSearchTerm]);

  return (
    <div
      className={classNames(styles.root, classNameSearch)}
      data-cy={TableSelectors.Search}
    >
      <Text
        size={size}
        onBlur={onBlur}
        onChange={onChange}
        name="searchTerm"
        placeholder={placeholder || formatMessage(texts.inputPlaceholder)}
        register={register}
        fullWidth
        className={styles.input}
        defaultValue={defaultValue}
        rounded={rounded}
      />
    </div>
  );
};
