import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  seoTitle: {
    id: 'ApplicationsHeader.seoTitle',
    description: 'Seo title',
    defaultMessage: 'Kontakter'
  },
  applications: {
    id: 'ApplicationsHeader.applications',
    description: 'Applications heading',
    defaultMessage: 'Ansökningar'
  }
});
