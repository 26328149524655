import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { LabeledText } from 'components/ui/forms';
import { Button, Spinner } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Keys } from 'consts/keys';
import { useKeyPress, useToast } from 'hooks';
import { UsersDocument, useSetUserQuotaMutation } from 'types/graphql';

import { texts } from './SmsQuotaModal.text';

import styles from './SmsQuotaModal.module.scss';

interface SmsQuotaModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customer: any | undefined;
  userLoading: boolean;
}

export const SmsQuotaModal = ({
  setIsOpen,
  customer,
  userLoading
}: SmsQuotaModalProps) => {
  useKeyPress(Keys.ESC, () => {
    setIsOpen(false);
  });

  const [setQuota, { error: setQuotaError }] = useSetUserQuotaMutation();
  const { addToast } = useToast();

  const { formatMessage } = useIntl();

  const { handleSubmit, register } = useForm();

  const onSubmit = useCallback(
    async ({ quota }) => {
      if (customer) {
        const { data } = await setQuota({
          variables: {
            userId: customer.user.id,
            quota: +quota
          },
          refetchQueries: [UsersDocument]
        });
        if (data?.setUserQuota) {
          addToast({
            title: formatMessage(texts.setQuotaSuccess),
            type: 'success'
          });
        }
        setIsOpen(false);
      }
    },
    [addToast, customer, formatMessage, setIsOpen, setQuota]
  );

  useEffect(() => {
    if (setQuotaError) {
      addToast({
        title: formatMessage(texts.setQuotaError),
        type: 'error'
      });
    }
  }, [addToast, formatMessage, setQuotaError]);

  return (
    <Modal isOpen onClose={() => setIsOpen(false)}>
      <div className={styles.main}>
        <Spinner visible={userLoading} />
        {!userLoading && (
          <>
            <h5 className={styles.heading}>
              <FormattedMessage {...texts.heading} />
            </h5>
            <div className={styles.bodyText}>
              <FormattedMessage {...texts.body} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.inputWrapper}>
                <LabeledText
                  type="number"
                  label={formatMessage(texts.heading)}
                  placeholder={formatMessage(texts.heading)}
                  fullWidth
                  name="quota"
                  register={register}
                />
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  className={styles.cancelButton}
                  color="secondary"
                  size="xl"
                  disabled={false}
                  onClick={() => setIsOpen(false)}
                >
                  <FormattedMessage {...texts.cancel} />
                </Button>
                <Button size="xl" type="submit">
                  <FormattedMessage {...texts.save} />
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};
