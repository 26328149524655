import { Suspense, useMemo } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
  ScrollToTop,
  RouterListener,
  PrivateRoute,
  AdminRoute
} from 'components/tools';
import { Layout } from 'components/ui/general';
import { ToastManager } from 'components/ui/modals';
import { Loading, Error } from 'components/ui/router';
import { BackofficePaths, ClientPaths } from 'consts/router';
import { routes } from 'routes';
import { sentry } from 'utils';

export const Router = () => {
  const backofficeRoutes = useMemo(
    () => [
      {
        path: BackofficePaths.Landing,
        element: <routes.backoffice.Landing />,
        isPrivate: true
      },
      {
        path: BackofficePaths.customers,
        element: <routes.backoffice.Customers />,
        isPrivate: true
      },
      {
        path: BackofficePaths.settings,
        element: <routes.backoffice.Settings />,
        isPrivate: true
      }
    ],
    []
  );

  const clientRoutes = useMemo(
    () => [
      {
        path: ClientPaths.Login,
        element: <routes.client.Login />,
        isPrivate: false
      },
      {
        path: ClientPaths.ForgotPassword,
        element: <routes.client.ForgotPassword />,
        isPrivate: false
      },
      {
        path: ClientPaths.ResetPassword,
        element: <routes.client.ResetPassword />,
        isPrivate: false
      },
      {
        path: ClientPaths.SetPassword,
        element: <routes.client.SetPassword />,
        isPrivate: false
      },
      {
        path: ClientPaths.SignUp,
        element: <routes.client.SignUp />,
        isPrivate: false
      },
      {
        path: ClientPaths.SignedUp,
        element: <routes.client.SignedUp />,
        isPrivate: false
      },
      {
        path: ClientPaths.Contacts,
        element: <routes.client.Contacts />
      },
      {
        path: ClientPaths.sendMessages,
        element: <routes.client.SendMessages />
      },
      {
        path: ClientPaths.settings,
        element: <routes.client.Settings />
      },
      {
        path: ClientPaths.OptOut,
        element: <routes.client.OptOut />,
        isPrivate: false
      }
    ],
    []
  );

  return (
    <BrowserRouter>
      <RouterListener />
      <ScrollToTop />
      <Layout>
        <sentry.ErrorBoundary fallback={<Error />}>
          <Suspense fallback={<Loading />}>
            <Routes>
              {backofficeRoutes.map(({ path, element, isPrivate = true }) => {
                if (isPrivate) {
                  return (
                    <Route key={path} path={path} element={<AdminRoute />}>
                      <Route path={path} element={element} />
                    </Route>
                  );
                }
                return <Route key={path} path={path} element={element} />;
              })}
              {clientRoutes.map(({ path, element, isPrivate = true }) => {
                if (isPrivate) {
                  return (
                    <Route key={path} path={path} element={<PrivateRoute />}>
                      <Route path={path} element={element} />
                    </Route>
                  );
                }
                return <Route key={path} path={path} element={element} />;
              })}
              <Route path="*" element={<routes.shared.NotFound />} />
            </Routes>
          </Suspense>
        </sentry.ErrorBoundary>
      </Layout>
      <ToastManager />
    </BrowserRouter>
  );
};
