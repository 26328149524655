import { ComponentProps } from 'react';

import { components } from 'react-select';

import { Icon } from 'components/ui/general';

import styles from '../Select.module.scss';

export const Option = (props: ComponentProps<typeof components.Option>) => {
  const { isSelected, label, isMulti } = props;
  const { selectProps }: { [key: string]: any } = props;

  return (
    <components.Option {...props}>
      {isMulti && !!selectProps.multiLabelWithNumber && (
        <>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => null}
            className={styles.optionInput}
          />
          <div className={styles.optionCheckboxHolder}>
            <Icon name="square" className={styles.optionSquare} />
            <Icon name="check-square" className={styles.optionCheckSquare} />
          </div>
        </>
      )}
      <div>{label}</div>
    </components.Option>
  );
};
