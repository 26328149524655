import 'react-app-polyfill/stable';
import { StrictMode } from 'react';

import { ApolloProvider } from '@apollo/client';
import { render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { factory } from 'api/apollo';
import { App } from 'components/tools';
import { TranslationsContextProvider } from 'context/translations';
import { store, persistor } from 'redux/store';
import { sentry, gtm } from 'utils';

import 'styles/_global.scss';

gtm.init();
sentry.init();

render(
  <StrictMode>
    <TranslationsContextProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloProvider client={factory(store)}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </TranslationsContextProvider>
  </StrictMode>,
  document.getElementById('root')
);
