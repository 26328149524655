import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.common,
  ...shared.form,
  heading: {
    id: 'DeleteContactListModal.heading',
    description: 'SMS quota modal heading text',
    defaultMessage: 'Vill du ta bort listan {name} med {number} mottagare?'
  },
  listRemoved: {
    id: 'DeleteContactListModal.listRemoved',
    description: 'Delete contact list success message',
    defaultMessage: 'Kontaktlistan borttagen'
  },
  listRemovedError: {
    id: 'DeleteContactListModal.listRemovedError',
    description: 'Delete contact list error message',
    defaultMessage: 'Kunde inte ta bort kontaktlistan'
  }
});
