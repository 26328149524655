import { FC } from 'react';

import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Search } from 'components/ui/table';

import { texts } from './ApplicationsHeader.text';

import styles from './ApplicationsHeader.module.scss';

interface ApplicationsHeaderProps {
  total?: number;
  onWatch: (s: string) => void;
}

export const ApplicationsHeader: FC<ApplicationsHeaderProps> = ({
  total = 0,
  onWatch
}) => {
  const { register, watch } = useForm();
  return (
    <div className={styles.root}>
      <div className={styles.aside}>
        <h5>
          <FormattedMessage {...texts.applications} />
        </h5>
        <div className={styles.countWrapper}>
          <p>{total}</p>
        </div>
        <Search
          register={register}
          watch={watch}
          onWatch={onWatch}
          defaultValue=""
          rounded
        />
      </div>
    </div>
  );
};
