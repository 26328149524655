import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  home: {
    id: 'Header.home',
    description: 'Home button text',
    defaultMessage: 'Hem'
  },
  customers: {
    id: 'Header.customers',
    description: 'Customers button text',
    defaultMessage: 'Kunder'
  },
  settings: {
    id: 'Header.settings',
    description: 'Settings button text',
    defaultMessage: 'Inställningar'
  }
});
