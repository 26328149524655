import { ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import {
  Gutter,
  Icon,
  Button,
  ButtonProps,
  Spinner
} from 'components/ui/general';
import { CardSelectors } from 'consts/cypress';
import { Names } from 'types/icon';

import styles from './AlertCard.module.scss';

export interface AlertCardProps {
  title?: ReactNode;
  message?: ReactNode;
  type?: 'info' | 'success' | 'error' | 'warning' | 'light' | 'dark';
  buttonRight?: ButtonProps | null;
  loading?: boolean;
}

export const AlertCard = ({
  title,
  message,
  type = 'light',
  buttonRight,
  loading
}: AlertCardProps) => {
  const icons = useMemo<{
    [key: string]: Names;
  }>(
    () => ({
      info: 'info-circle',
      success: 'check-circle',
      warning: 'exclamation-triangle',
      error: 'exclamation-circle'
    }),
    []
  );

  return (
    <div
      className={classNames(styles.root, {
        [styles[type]]: true
      })}
      data-cy={CardSelectors.Root}
    >
      {!!icons[type] && (
        <div className={styles.iconHolder}>
          <Icon name={icons[type]} />
        </div>
      )}
      <div className={styles.titleAndMessage}>
        <Gutter gutter={{ bottom: 1 }}>
          {title && (
            <Gutter.Item>
              <h6 className={styles.title}>{title}</h6>
            </Gutter.Item>
          )}
          {message && (
            <Gutter.Item>
              <p className={styles.message}>{message}</p>
            </Gutter.Item>
          )}
        </Gutter>
      </div>
      {!!buttonRight && !loading && (
        <Button
          className={classNames(styles.delete, buttonRight?.children)}
          aria-label="Delete card"
          naked
          stripPadding
          {...buttonRight}
        >
          {buttonRight.children || <Icon name="times" />}
        </Button>
      )}
      {!!loading && <Spinner visible />}
    </div>
  );
};
