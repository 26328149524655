export enum TwitterCards {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
  App = 'app',
  Player = 'player'
}

export enum OGTypes {
  Article = 'article',
  Book = 'book',
  Profile = 'profile',
  Website = 'website'
}
