import { ComponentProps } from 'react';

import { components } from 'react-select';

import { Spinner } from 'components/ui/general';

import styles from '../Select.module.scss';

export const LoadingIndicator = (
  props: ComponentProps<typeof components.LoadingIndicator>
) => {
  return (
    <div className={styles.loadingIndicator}>
      <components.LoadingIndicator {...props} />
      <Spinner visible size="sm" />
    </div>
  );
};
