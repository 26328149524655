import { defineMessages } from 'react-intl';

import { shared } from 'lang/shared.text';

export const texts = defineMessages({
  ...shared.common,
  ...shared.table,
  fetchError: {
    id: 'ApplicationsTable.fetchError',
    description: 'Fetch error toast title',
    defaultMessage: 'Något gick fel i hämtningen'
  }
});
