import { Dispatch, SetStateAction, useCallback } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Spinner } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Keys } from 'consts/keys';
import { useKeyPress, useToast } from 'hooks';
import {
  Scalars,
  useAddContactListContactsMutation,
  useContactListsQuery
} from 'types/graphql';

import { texts } from './AddContactsToListModal.text';

import styles from './AddContactsToListModal.module.scss';

interface SendMessageModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedIds: Array<Scalars['ID']>;
  setSelectedIds: Dispatch<SetStateAction<Array<Scalars['ID']>>>;
}

export const AddContactsToListModal = ({
  setIsOpen,
  selectedIds,
  setSelectedIds
}: SendMessageModalProps) => {
  useKeyPress(Keys.ESC, () => {
    setIsOpen(false);
  });

  const { addToast } = useToast();

  const { data: contactListsData, loading: contactListsLoading } =
    useContactListsQuery({
      onError: () => {
        addToast({
          title: formatMessage(texts.fetchListError),
          type: 'error'
        });
      }
    });

  const [addContactsToList] = useAddContactListContactsMutation({
    onError: () => {
      addToast({
        title: formatMessage(texts.addContactToListError),
        type: 'error'
      });
    },
    onCompleted: () => {
      setSelectedIds([]);
      setIsOpen(false);
      addToast({
        title: formatMessage(texts.contactsAdded),
        type: 'success'
      });
    }
  });

  const handleAddContactsToList = useCallback(
    (contactListId) => {
      if (!contactListId) {
        return;
      }

      return addContactsToList({
        variables: {
          input: {
            contactIds: selectedIds,
            contactListId
          }
        }
      });
    },
    [addContactsToList, selectedIds]
  );

  const renderContactLists = useCallback(() => {
    if (contactListsData) {
      return contactListsData?.contactLists.edges.map((list) => {
        return (
          <Button
            naked
            stripPadding
            className={styles.listItem}
            onClick={() => handleAddContactsToList(list.id)}
          >
            {list.name}
          </Button>
        );
      });
    }
  }, [contactListsData, handleAddContactsToList]);

  const { formatMessage } = useIntl();

  return (
    <Modal isOpen onClose={() => setIsOpen(false)}>
      <div className={styles.main}>
        <div className={styles.heading}>
          <FormattedMessage {...texts.heading} /> ({selectedIds.length})
        </div>
        <Spinner visible={contactListsLoading} />
        <div className={styles.list}>{renderContactLists()}</div>
        <Button
          size="lg"
          color="secondary"
          onClick={() => {
            setSelectedIds([]);
            setIsOpen(false);
          }}
          className={styles.cancelButton}
        >
          <FormattedMessage {...texts.cancel} />
        </Button>
      </div>
    </Modal>
  );
};
