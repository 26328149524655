import { format as fnsFormat } from 'date-fns';
import { sv } from 'date-fns/locale';

import { DateFormat } from 'consts/date';

const formatDate = ({
  date,
  format = DateFormat.Date
}: {
  date: Date;
  format?: DateFormat;
}) => {
  return fnsFormat(new Date(date), format, { locale: sv });
};

const formatCurrency = (number: number, options?: Intl.NumberFormatOptions) => {
  if (typeof number === 'number') {
    return number.toLocaleString('sv-SE', {
      style: 'currency',
      currency: 'SEK',
      currencyDisplay: 'code',
      maximumFractionDigits: 0,
      ...options
    });
  }

  return number;
};

export const format = {
  date: formatDate,
  currency: formatCurrency
};
