/* eslint-disable @interactive-solutions/eslint-react/id-filename-mismatch */
/**
 * Collect shared messages within this file. Enums from backend is great to
 * translate here.
 */

import { defineMessages } from 'react-intl';

// enum UserRole
const userRole = defineMessages({
  admin: {
    id: 'userRole.admin',
    description: 'Admin',
    defaultMessage: 'Superadmin'
  },
  company_admin: {
    id: 'userRole.company_admin',
    description: 'Company admin',
    defaultMessage: 'Admin'
  },
  company_user: {
    id: 'userRole.company_user',
    description: 'Company user',
    defaultMessage: 'Bokare'
  },
  user: {
    id: 'userRole.user',
    description: 'User',
    defaultMessage: 'Användare'
  }
});

const common = defineMessages({
  cancel: {
    id: 'common.cancel',
    description: 'cancel',
    defaultMessage: 'Avbryt'
  },
  save: {
    id: 'common.save',
    description: 'save',
    defaultMessage: 'Spara'
  },
  accept: {
    id: 'common.accept',
    description: 'accept',
    defaultMessage: 'Acceptera'
  },
  delete: {
    id: 'common.delete',
    description: 'delete',
    defaultMessage: 'Ta bort'
  },
  block: {
    id: 'common.block',
    description: 'block',
    defaultMessage: 'Blockera användaren'
  },
  view: {
    id: 'common.view',
    description: 'view',
    defaultMessage: 'Visa'
  },
  close: {
    id: 'common.close',
    description: 'close',
    defaultMessage: 'Stäng'
  }
});

// Table messages
const table = defineMessages({
  columnName: {
    id: 'table.columnName',
    description: 'Column full name',
    defaultMessage: 'Namn'
  },
  columnFirstName: {
    id: 'table.columnFirstName',
    description: 'Column first name',
    defaultMessage: 'Förnamn'
  },
  columnLastName: {
    id: 'table.columnLastName',
    description: 'Column last name',
    defaultMessage: 'Efternamn'
  },
  columnSenderName: {
    id: 'table.columnSenderName',
    description: 'Column sender name',
    defaultMessage: 'Avsändarnamn'
  },
  columnMobileNumber: {
    id: 'table.columnMobileNumber',
    description: 'Column mobile number',
    defaultMessage: 'Telefonnummer'
  },
  columnOrganisationNumber: {
    id: 'table.columnOrganisationNumber',
    description: 'Column organization number',
    defaultMessage: 'Organisationsnummer'
  },
  columnEmail: {
    id: 'table.columnEmail',
    description: 'Column email',
    defaultMessage: 'E-post'
  },
  columnCompanyNumber: {
    id: 'table.columnCompanyNumber',
    description: 'Column Company Number',
    defaultMessage: 'Företagsnummer'
  },
  columnOrgNumber: {
    id: 'table.columnOrgNumber',
    description: 'Column Organisation Number',
    defaultMessage: 'Organisationsnummer'
  },
  columnStatus: {
    id: 'table.columnStatus',
    description: 'Column status',
    defaultMessage: 'Status'
  },
  columnSmsQuota: {
    id: 'table.columnSmsQuota',
    description: 'Column SMS quota',
    defaultMessage: 'SMS-kvot'
  },
  columnSmsHistory: {
    id: 'table.columnSmsHistory',
    description: 'Column SMS history',
    defaultMessage: 'SMS-historik'
  },
  columnCreatedAt: {
    id: 'table.columnCreatedAt',
    description: 'Column created at',
    defaultMessage: 'Tillagd'
  },
  emptyTable: {
    id: 'table.emptyTable',
    description: 'Empty table',
    defaultMessage: 'Hittade inga resultat'
  }
});

// form messages
const form = defineMessages({
  firstName: {
    id: 'form.firstName',
    description: 'Form first name',
    defaultMessage: 'Förnamn'
  },
  lastName: {
    id: 'form.lastName',
    description: 'Form last name',
    defaultMessage: 'Efternamn'
  },
  mobileNumber: {
    id: 'form.mobileNumber',
    description: 'Form mobile number',
    defaultMessage: 'Telefonnummer  (+46701112233)'
  },
  email: {
    id: 'form.email',
    description: 'Form email',
    defaultMessage: 'E-post'
  },
  list: {
    id: 'form.list',
    description: 'Form list',
    defaultMessage: 'Lägg till i lista'
  },
  company: {
    id: 'form.company',
    description: 'Form company name',
    defaultMessage: 'Avsändarnamn'
  },
  companyNumber: {
    id: 'form.companyNumber',
    description: 'Form company number',
    defaultMessage: 'Företagsnummer'
  },
  organizationNumber: {
    id: 'form.organizationNumber',
    description: 'Form organisation number',
    defaultMessage: 'Organisationsnummer'
  },
  date: {
    id: 'form.date',
    description: 'Form date',
    defaultMessage: 'Datum'
  },
  reciever: {
    id: 'form.reciever',
    description: 'Form reciever',
    defaultMessage: 'Mottagare'
  },
  message: {
    id: 'form.message',
    description: 'Form message',
    defaultMessage: 'Meddelande'
  }
});

export const shared = {
  userRole,
  common,
  table,
  form
};
