import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  sendMessageError: {
    id: 'SendMessageTabs.sendMessageError',
    description: 'Error message for tabs',
    defaultMessage: 'Kunde inte hitta kontakter'
  },
  tabsAll: {
    id: 'SendMessageTabs.tabsAll',
    description: 'Tabs label',
    defaultMessage: 'Alla'
  },
  tabsContacts: {
    id: 'SendMessageTabs.tabsContacts',
    description: 'Tabs label',
    defaultMessage: 'Kontakter'
  },
  tabsLists: {
    id: 'SendMessageTabs.tabsLists',
    description: 'Tabs label',
    defaultMessage: 'Listor'
  }
});
