import { ComponentProps } from 'react';

import { components } from 'react-select';

import { Icon } from 'components/ui/general';

export const DropdownIndicator = (
  props: ComponentProps<typeof components.DropdownIndicator>
) => {
  const { selectProps }: { [key: string]: any } = props;
  const { iconDropdownIndicator } = selectProps;

  if (iconDropdownIndicator)
    return (
      <components.DropdownIndicator {...props}>
        <Icon name={iconDropdownIndicator} />
      </components.DropdownIndicator>
    );

  return <components.DropdownIndicator {...props} />;
};
