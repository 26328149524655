import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';

interface AccordionContextProviderProps {
  children: ReactNode;
  activeIds?: string[];
}

export const AccordionContext = createContext<
  [string[], Dispatch<SetStateAction<string[]>>]
>([[], () => {}]);

export const AccordionContextProvider = ({
  children,
  activeIds
}: AccordionContextProviderProps) => {
  const [activeItems, setActiveItems] = useState<string[]>(activeIds || []);

  useEffect(() => {
    if (activeIds) setActiveItems(activeIds);
  }, [activeIds]);

  const value = useMemo<[string[], Dispatch<SetStateAction<string[]>>]>(
    () => [activeItems, setActiveItems],
    [activeItems]
  );

  return (
    <AccordionContext.Provider value={value}>
      {children}
    </AccordionContext.Provider>
  );
};

export const AccordionContextConsumer = AccordionContext.Consumer;
