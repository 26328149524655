import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Keys } from 'consts/keys';
import { useKeyPress, useToast } from 'hooks';
import {
  ContactList as ContactListType,
  ContactListDocument,
  useDeleteContactListMutation
} from 'types/graphql';

import { texts } from './DeleteContactListModal.text';

import styles from './DeleteContactListModal.module.scss';

interface DeleteContactListModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  list?: ContactListType;
  refetch: () => void;
  setSelectedContact: (c: ContactListType | undefined) => void;
}

export const DeleteContactListModal = ({
  setIsOpen,
  list,
  refetch,
  setSelectedContact
}: DeleteContactListModalProps) => {
  useKeyPress(Keys.ESC, () => {
    setIsOpen(false);
  });
  const { addToast } = useToast();
  const { formatMessage } = useIntl();

  const [deleteList, { error: deleteListError }] =
    useDeleteContactListMutation();

  const handleDeleteList = useCallback(async () => {
    if (list) {
      const { data } = await deleteList({
        variables: {
          id: list.id
        },
        refetchQueries: [ContactListDocument]
      });
      if (data) {
        addToast({
          title: formatMessage(texts.listRemoved),
          type: 'success'
        });
      }
      setSelectedContact(undefined);
      setIsOpen(false);
      refetch();
    }
  }, [
    addToast,
    deleteList,
    formatMessage,
    list,
    refetch,
    setIsOpen,
    setSelectedContact
  ]);

  useEffect(() => {
    if (deleteListError) {
      addToast({
        title: formatMessage(texts.listRemovedError),
        type: 'error'
      });
    }
  }, [addToast, deleteListError, formatMessage]);

  return (
    <Modal isOpen onClose={() => setIsOpen(false)}>
      <div className={styles.main}>
        <h6 className={styles.heading}>
          {formatMessage(texts.heading, {
            name: list?.name,
            number: list?.contactCount
          })}
        </h6>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.cancelButton}
            color="secondary"
            size="xl"
            disabled={false}
            onClick={() => setIsOpen(false)}
          >
            <FormattedMessage {...texts.cancel} />
          </Button>
          <Button size="xl" color="error" onClick={handleDeleteList}>
            <FormattedMessage {...texts.delete} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
