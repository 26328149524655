import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  heading: {
    id: 'AddContactsToListModal.heading',
    description: 'Add to list modal heading',
    defaultMessage: 'Lägg till i lista'
  },
  cancel: {
    id: 'AddContactsToListModal.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Avbryt'
  },
  addContactToListError: {
    id: 'AddContactsToListModal.addContactToListError',
    description: 'Add contact to list error text',
    defaultMessage: 'Något gick fel när användarna skulle läggas till i listan'
  },
  contactsAdded: {
    id: 'AddContactsToListModal.contactsAdded',
    description: 'Contacts added toast text',
    defaultMessage: 'Kontakter tillagda i listan'
  },
  fetchListError: {
    id: 'AddContactsToListModal.fetchListError',
    description: 'Error getting contactlists message',
    defaultMessage: 'Kunde inte hämta kontaktlistor'
  }
});
