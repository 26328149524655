import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  page: {
    id: 'Pagination.page',
    description: 'Page number',
    defaultMessage: 'Sida'
  },
  of: {
    id: 'Pagination.of',
    description: 'Page number of number',
    defaultMessage: 'av'
  }
});
