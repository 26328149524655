import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { ToggleBlockSwitch } from 'components/ui/forms';
import { Button } from 'components/ui/general';
import { SmsHistoryModal, SmsQuotaModal } from 'components/ui/modals';
import { Table, TableFetchData } from 'components/ui/table';
import { useToast } from 'hooks';
import { User, useUserLazyQuery, useUsersLazyQuery } from 'types/graphql';
import { table } from 'utils';

import { texts } from './CustomersTable.text';

import styles from './CustomersTable.module.scss';

interface CustomersTableProps {
  searchTerm?: string;
}

export const CustomersTable: FC<CustomersTableProps> = ({ searchTerm }) => {
  const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);
  const [isSmsHistoryModalOpen, setIsSmsHistoryModalOpen] = useState(false);

  const [fetchUsers, { data, loading, error }] = useUsersLazyQuery({});
  const [
    fetchUser,
    { data: userData, loading: userLoading, error: userError }
  ] = useUserLazyQuery();

  const { addToast } = useToast();
  const { formatMessage } = useIntl();

  const handleModals = useCallback(
    async (id, type) => {
      fetchUser({
        variables: {
          id
        }
      });
      if (type === 'quota') setIsQuotaModalOpen(true);
      if (type === 'history') setIsSmsHistoryModalOpen(true);
      if (type === null) {
        setIsQuotaModalOpen(false);
        setIsSmsHistoryModalOpen(false);
      }
    },
    [fetchUser]
  );

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.fetchError),
        type: 'error'
      });
    }
    if (userError) {
      addToast({
        title: `${formatMessage(texts.fetchError)}n`,
        type: 'error'
      });
    }
  }, [addToast, error, formatMessage, userError]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: TableFetchData) => {
      fetchUsers({
        variables: {
          filter: {
            offset: pageIndex * pageSize,
            limit: pageSize,
            ...(searchTerm ? { searchTerm } : {}),
            approved: true
          },
          sorting: {
            sorting: sortBy.map(({ desc }) => ({
              field: 'createdAt',
              direction: desc ? 'asc' : 'desc'
            }))
          }
        }
      });
    },
    [fetchUsers, searchTerm]
  );

  const showQuota = useCallback((user) => {
    const sent = user.quota - user.remainingSMSQuota;
    return (
      <div className={styles.quota}>
        {sent}/{user.quota}
      </div>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.columnName} />,
        accessor: 'lastName',
        Cell: ({ row: { original } }: any) => {
          return `${original.firstName} ${original.lastName}` as string;
        }
      },
      {
        Header: <FormattedMessage {...texts.columnSenderName} />,
        accessor: 'senderName'
      },
      {
        Header: <FormattedMessage {...texts.columnEmail} />,
        accessor: 'email'
      },
      {
        Header: <FormattedMessage {...texts.columnMobileNumber} />,
        accessor: 'mobileNumber'
      },
      {
        Header: <FormattedMessage {...texts.columnOrgNumber} />,
        accessor: 'organizationNumber'
      },
      {
        Header: <FormattedMessage {...texts.columnStatus} />,
        accessor: 'blocked',
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => {
          return <ToggleBlockSwitch user={original as User} />;
        }
      },
      {
        Header: <FormattedMessage {...texts.columnSmsQuota} />,
        accessor: 'smsQuota',
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => {
          return (
            <Button
              color="dark"
              naked
              stripPadding
              onClick={() => handleModals(original.id, 'quota')}
            >
              <div className={styles.tableButtonWrapper}>
                {showQuota(original)}
              </div>
            </Button>
          );
        }
      },
      {
        Header: <FormattedMessage {...texts.columnSmsHistory} />,
        accessor: 'smsHistory',
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => {
          return (
            <Button
              color="dark"
              naked
              stripPadding
              onClick={() => handleModals(original.id, 'history')}
            >
              <div className={styles.tableButtonWrapper}>
                <FormattedMessage {...texts.view} />
              </div>
            </Button>
          );
        }
      }
    ],
    [handleModals, showQuota]
  );

  return (
    <>
      <div className={styles.root}>{searchTerm}</div>
      <Table
        columns={columns}
        data={data?.users.edges || []}
        onFetchData={fetchData}
        totalPages={table.getTotalPages(data?.users.meta)}
        pagination
        loading={loading}
        sort
        initialSortCreatedAt
        searchTermProp={searchTerm}
      />
      {isQuotaModalOpen && (
        <SmsQuotaModal
          setIsOpen={setIsQuotaModalOpen}
          customer={userData}
          userLoading={userLoading}
        />
      )}
      {isSmsHistoryModalOpen && (
        <SmsHistoryModal
          setIsOpen={setIsSmsHistoryModalOpen}
          customer={userData}
          userLoading={userLoading}
        />
      )}
    </>
  );
};
