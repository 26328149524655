const getTotalPages = ({
  total,
  limit
}: {
  total?: number;
  limit?: number;
} = {}) => {
  if (total && limit) return Math.ceil(total / limit);
};

const getActiveFilter = ({
  activeFilter,
  type = 'array'
}: { activeFilter?: string[]; type?: 'array' } = {}) => {
  let filters: {
    [key: string]: any;
  } = {};

  if (type === 'array') {
    activeFilter?.forEach((filter) => {
      const [key, value] = filter.split('_');
      filters = {
        ...filters,
        [key]: [...((filters as { [key: string]: any })[key] || []), value]
      };
    });
  }

  return filters;
};

export const table = {
  getTotalPages,
  getActiveFilter
};
