import { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Portal } from 'components/tools';
import { Button, Gutter, Grid } from 'components/ui/general';
import { GdprConsentSelectors } from 'consts/cypress';
import { Consents } from 'consts/gdpr';
import { Events } from 'consts/gtm';
import { selectConsent, selectMadeDecision, setConsent } from 'redux/gdpr';
import { gtm } from 'utils';

import { texts } from './GdprConsent.text';

import styles from './GdprConsent.module.scss';

export const GdprConsent = () => {
  const dispatch = useDispatch();
  const [hasUpdatedConsent, setHasUpdatedConsent] = useState(false);
  const selectorMadeDecision = useSelector(selectMadeDecision);
  const selectorConsent = useSelector(selectConsent);
  const { formatMessage } = useIntl();

  const updateConsent = useCallback(
    (consent: Consents) => {
      dispatch(setConsent([consent]));
      setHasUpdatedConsent(true);
    },
    [dispatch]
  );

  useEffect(() => {
    if (hasUpdatedConsent) {
      gtm.push({
        dataLayer: {
          event: Events.Consent,
          consent: selectorConsent
        }
      });
    }
  }, [selectorConsent, hasUpdatedConsent]);

  if (!selectorMadeDecision) {
    return (
      <Portal>
        <div className={styles.root} data-cy={GdprConsentSelectors.Root}>
          <Gutter gutter={{ bottom: 1 }}>
            <Gutter.Item>
              <h6>{formatMessage(texts.heading)}</h6>
            </Gutter.Item>
            <Gutter.Item>
              <p>{formatMessage(texts.paragraph)}</p>
            </Gutter.Item>
            <Gutter.Item>
              <Grid gutter={{ left: 1 }}>
                <Grid.Item>
                  <Button onClick={() => updateConsent(Consents.All)}>
                    {formatMessage(texts.buttonAllow)}
                  </Button>
                </Grid.Item>
                <Grid.Item>
                  <Button
                    onClick={() => updateConsent(Consents.Necessary)}
                    ghost
                  >
                    {formatMessage(texts.buttonDecline)}
                  </Button>
                </Grid.Item>
              </Grid>
            </Gutter.Item>
          </Gutter>
        </div>
      </Portal>
    );
  }

  return null;
};
