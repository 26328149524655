import { MouseEventHandler, ReactNode, useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { Link, LinkProps } from 'components/tools';
import { Icon, IconProps, Spinner } from 'components/ui/general';
import { ButtonSelectors } from 'consts/cypress';
import { Fonts } from 'types/icon';

import styles from './Button.module.scss';

export interface ButtonProps {
  children?: ReactNode;
  type?: 'submit' | 'reset' | 'button';
  href?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  ghost?: boolean;
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'nav'
    | 'dark'
    | 'error'
    | 'light'
    | 'success-light'
    | 'error-light'
    | 'warning'; // color names $all-colors
  target?: string;
  to?: LinkProps['to'];
  as?: keyof JSX.IntrinsicElements;
  fullWidth?: boolean;
  loading?: boolean;
  iconRight?: IconProps;
  iconLeft?: IconProps;
  rounded?: boolean;
  naked?: boolean;
  stripPadding?: boolean;
  [key: string]: any;
}

export const Button = ({
  children,
  type,
  href,
  size = 'md',
  className,
  disabled,
  onClick,
  ghost,
  color = 'primary',
  target,
  to,
  as,
  fullWidth,
  loading,
  iconRight,
  iconLeft,
  rounded = true,
  naked,
  stripPadding,
  ...props
}: ButtonProps) => {
  const ElementType = useMemo<any>(() => {
    if (to || href) return Link;
    return as || 'button';
  }, [as, href, to]);

  const getType = useMemo(() => {
    if (type) {
      return type;
    }

    if (ElementType === 'button') {
      return 'button';
    }
  }, [ElementType, type]);

  const getIconFont = useCallback(
    ({ font }: { font?: Fonts }) => {
      if (font) {
        return font;
      }

      switch (size) {
        case 'sm':
        case 'md':
        case 'lg':
        case 'xl':
          // TODO: Sync with the design system for the current project
          return 'madrid';

        default:
          // TODO: Sync with the design system for the current project
          return 'madrid';
      }
    },
    [size]
  );

  return (
    <ElementType
      {...props}
      type={getType}
      className={classNames(styles.root, className, {
        [styles.disabled]: disabled,
        [styles.ghost]: ghost,
        [styles[`${color}Color`]]: color,
        [styles[`${size}Size`]]: size,
        [styles.fullWidth]: fullWidth,
        [styles.loading]: loading,
        [styles.rounded]: rounded,
        [styles.hasChildren]: !!children,
        [styles.naked]: naked,
        [styles.stripPadding]: stripPadding
      })}
      to={to || href}
      disabled={disabled}
      onClick={onClick && !disabled ? onClick : null}
      target={target && href ? target : null}
      data-cy={ButtonSelectors.Root}
    >
      <span className={styles.content}>
        {!!iconLeft && (
          <div className={styles.iconLeft}>
            <Icon name={iconLeft.name} font={getIconFont(iconLeft)} />
          </div>
        )}
        {!!children && children}
        {!!iconRight && (
          <div className={styles.iconRight}>
            <Icon name={iconRight.name} font={getIconFont(iconRight)} />
          </div>
        )}
      </span>
      <div className={styles.spinner}>
        <Spinner
          visible={!!loading}
          size="sm"
          color={naked ? 'dark' : 'light'}
        />
      </div>
    </ElementType>
  );
};
