import { ReactNode } from 'react';

import {
  Link as RouterLink,
  NavLink as RouterNavLink,
  LinkProps as RouterLinkLink,
  NavLinkProps
} from 'react-router-dom';

export interface LinkProps
  extends Omit<RouterLinkLink, 'className' | 'style'>,
    Omit<NavLinkProps, 'className' | 'style'> {
  children: ReactNode;
  className?: RouterLinkLink['className'] | NavLinkProps['className'];
  style?: RouterLinkLink['style'] | NavLinkProps['style'];
  [key: string]: any;
}

export const Link = ({
  children,
  to,
  className,
  style,
  ...props
}: LinkProps) => {
  if (typeof to === 'function') {
    return null;
  }

  let internal = true;
  const getTo = typeof to === 'string' ? to : to.pathname;
  const isNotNavLink =
    typeof className !== 'function' && typeof style !== 'function';

  if (getTo) {
    internal = /^\/(?!\/)|^#/.test(getTo);
  }

  if (internal) {
    if (isNotNavLink) {
      return (
        <RouterLink to={to} className={className} style={style} {...props}>
          {children}
        </RouterLink>
      );
    }

    return (
      <RouterNavLink to={to} className={className} style={style} {...props}>
        {children}
      </RouterNavLink>
    );
  }

  if (isNotNavLink) {
    let href = getTo;

    if (!href?.startsWith('http://') && !href?.startsWith('https://')) {
      href = `//${href}`;
    }

    return (
      <a href={href} className={className} style={style} {...props}>
        {children}
      </a>
    );
  }

  return null;
};
