import { useEffect } from 'react';

import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Events } from 'consts/gtm';
import { setLocation, setPreviousLocation, selectLocation } from 'redux/router';
import { gtm } from 'utils';

export const RouterListener = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectorLocation = useSelector(selectLocation);

  useEffect(() => {
    const locationChanged = !isEqual(
      omit(location, 'key'),
      omit(selectorLocation, 'key')
    );

    if (locationChanged) {
      dispatch(setLocation(location));
      dispatch(setPreviousLocation(selectorLocation));
      gtm.push({
        dataLayer: {
          event: Events.PageView
        }
      });
    }
  }, [dispatch, location, selectorLocation]);

  return null;
};
