import { ChangeEvent, MutableRefObject, ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import { RegisterOptions, UseFormRegister, FieldErrors } from 'react-hook-form';

import { Error } from 'components/ui/forms';
import { SwitchSelectors } from 'consts/cypress';

import styles from './Switch.module.scss';

export interface SwitchProps {
  name: string;
  size?: 'md';
  labelRight?: ReactNode;
  labelLeft?: ReactNode;
  switchTextRight?: ReactNode;
  switchTextLeft?: ReactNode;
  ariaLabel?: string;
  className?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  validation?: RegisterOptions;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: FieldErrors;
  defaultChecked?: boolean;
  checkboxRef?: MutableRefObject<HTMLInputElement | null>;
}

export const Switch = ({
  name,
  size = 'md',
  ariaLabel,
  className,
  disabled,
  register,
  validation = {},
  onChange,
  onBlur,
  labelRight,
  labelLeft,
  error,
  defaultChecked,
  checkboxRef,
  switchTextRight,
  switchTextLeft
}: SwitchProps) => {
  const getValidation = useMemo(() => {
    return !disabled ? validation : {};
  }, [disabled, validation]);

  const registerHolder = useMemo(
    () => register(name, getValidation),
    [name, register, getValidation]
  );

  return (
    <>
      <label
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
          [styles[`${size}Size`]]: size
        })}
        data-cy={SwitchSelectors.Root}
      >
        <input
          type="checkbox"
          disabled={disabled}
          aria-label={ariaLabel}
          className={styles.input}
          defaultChecked={defaultChecked}
          data-cy={SwitchSelectors.Input}
          {...registerHolder}
          ref={(event) => {
            if (checkboxRef) checkboxRef.current = event;
            registerHolder.ref(event);
          }}
          onChange={(event) => {
            registerHolder.onChange(event);
            onChange?.(event);
          }}
          onBlur={(event) => {
            registerHolder.onBlur(event);
            onBlur?.(event);
          }}
        />
        {!!labelLeft && (
          <div className={styles.labelLeft}>
            {labelLeft}
            {getValidation.required ? ' *' : ''}
          </div>
        )}
        <div className={styles.switchHolder}>
          {!!switchTextLeft && (
            <div className={styles.switchTextLeft}>{switchTextLeft}</div>
          )}
          <div className={styles.switch} />
          {!!switchTextRight && (
            <div className={styles.switchTextRight}>{switchTextRight}</div>
          )}
        </div>
        {!!labelRight && (
          <div className={styles.labelRight}>
            {labelRight}
            {getValidation.required ? ' *' : ''}
          </div>
        )}
      </label>
      <Error error={error} />
    </>
  );
};
