import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { getMonth } from 'date-fns';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Select } from 'components/ui/forms';
import { Button, Empty, Grid } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Table, TableFetchData } from 'components/ui/table';
import { DateFormat } from 'consts/date';
import { useDownloadFile, useToast } from 'hooks';
import {
  useExportMessageHistoryLazyQuery,
  useMessagesLazyQuery
} from 'types/graphql';
import { table, format } from 'utils';

import { texts } from './SmsHistoryModal.text';

import styles from './SmsHistoryModal.module.scss';

interface SmsHistoryModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  customer: any;
  userLoading: boolean;
}

export const SmsHistoryModal = ({
  setIsOpen,
  customer,
  userLoading
}: SmsHistoryModalProps) => {
  const { control } = useForm();
  const [month, setMonth] = useState<{ label: string; value: string } | null>(
    null
  );
  const [year, setYear] = useState<{ label: string; value: string } | null>(
    null
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { addToast } = useToast();
  const { formatMessage } = useIntl();
  const { download, loading, error } = useDownloadFile();
  const [fetchMessages, { data: messagesData, loading: messagesLoading }] =
    useMessagesLazyQuery({
      onError: () => {
        addToast({
          title: formatMessage(texts.getMessagesError),
          type: 'error'
        });
      }
    });

  const [exportMessages, { data: exportData, loading: exportLoading }] =
    useExportMessageHistoryLazyQuery({
      onError: () => {
        addToast({
          title: formatMessage(texts.exportError),
          type: 'error'
        });
      },
      fetchPolicy: 'no-cache'
    });

  const months = [
    {
      label: 'Januari',
      value: '01'
    },
    {
      label: 'Februari',
      value: '02'
    },
    {
      label: 'Mars',
      value: '03'
    },
    {
      label: 'April',
      value: '04'
    },
    {
      label: 'Maj',
      value: '05'
    },
    {
      label: 'Juni',
      value: '06'
    },
    {
      label: 'Juli',
      value: '07'
    },
    {
      label: 'Augusti',
      value: '08'
    },
    {
      label: 'September',
      value: '09'
    },
    {
      label: 'Oktober',
      value: '10'
    },
    {
      label: 'November',
      value: '11'
    },
    {
      label: 'December',
      value: '12'
    }
  ];

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage {...texts.date} />,
        accessor: 'createdAt',
        Cell: ({
          row: {
            original: { createdAt }
          }
        }: any) => {
          return format.date({ date: createdAt, format: DateFormat.DateTime });
        }
      },
      {
        Header: <FormattedMessage {...texts.reciever} />,
        accessor: 'receiver',
        Cell: ({
          row: {
            original: { receiver }
          }
        }: any) => {
          if (receiver.firstName) {
            return `${receiver.firstName} ${receiver.lastName}`;
          }
          if (receiver.name) return receiver.name;
          return <FormattedMessage {...texts.noReceiver} />;
        }
      },
      {
        Header: <FormattedMessage {...texts.message} />,
        accessor: 'message'
      }
    ],
    []
  );

  const exportReport = useCallback(() => {
    if (startDate && endDate && customer) {
      exportMessages({
        variables: {
          filter: {
            createdById: customer.user.id,
            createdAt: { from: startDate, to: endDate }
          },
          sorting: {}
        }
      });
    }
  }, [customer, endDate, exportMessages, startDate]);

  useEffect(() => {
    const downloadHistory = async () => {
      if (exportData?.exportMessageHistory) {
        await download(exportData?.exportMessageHistory);
      }
    };
    downloadHistory();
  }, [download, exportData?.exportMessageHistory]);

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.downloadError),
        type: 'error'
      });
    }
  }, [addToast, error, formatMessage]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: TableFetchData) => {
      if (startDate && endDate && customer) {
        fetchMessages({
          variables: {
            filter: {
              offset: pageIndex * pageSize,
              limit: pageSize,
              createdById: customer.user.id,
              createdAt: { from: startDate, to: endDate }
            },
            sorting: {
              sorting: sortBy.map(({ desc }) => ({
                field: 'createdAt',
                direction: !desc ? 'asc' : 'desc'
              }))
            }
          }
        });
      }
    },
    [startDate, endDate, customer, fetchMessages]
  );

  const renderYears = useCallback(() => {
    const FIRSTYEAR = 2022;
    const currentYear = new Date().getFullYear();
    const yearOptions = [{}];
    for (let i = 0; i <= currentYear - FIRSTYEAR; i += 1) {
      yearOptions.push({
        label: (FIRSTYEAR + i).toString(),
        value: `${FIRSTYEAR + i}-`
      });
    }
    return yearOptions;
  }, []);

  const handleSelect = useCallback((setter, event) => {
    if (setter === 'month') {
      setMonth({ label: event.label, value: event.value });
    }
    if (setter === 'year') {
      setYear({ label: event.label, value: event.value });
    }
  }, []);

  useEffect(() => {
    if (year && month) {
      const firstDay = new Date(`${year.value + month.value}-01`);
      firstDay.setDate(1);
      setStartDate(firstDay);
      const lastDay = new Date(`${year.value + month.value}-01`);
      lastDay.setMonth(getMonth(lastDay) + 1);
      lastDay.setDate(1);
      setEndDate(lastDay);
    }
  }, [month, year]);

  if (!customer) return null;

  return (
    <Modal isOpen onClose={() => setIsOpen(false)} middleSize="xl">
      <div className={styles.modalBody}>
        <h5 className={styles.heading}>
          <FormattedMessage {...texts.heading} />
        </h5>
        <div className={styles.tableHeader}>
          <div className={styles.selectWrapper}>
            <Grid fill gutter={{ left: 1 }}>
              <Grid.Item width={8}>
                <Select
                  fullWidth
                  placeholder="Månad"
                  name="month"
                  control={control}
                  options={months}
                  onChange={(event: any) => handleSelect('month', event)}
                />
              </Grid.Item>
              <Grid.Item width={4}>
                <Select
                  fullWidth
                  placeholder="År"
                  name="year"
                  control={control}
                  options={renderYears()}
                  onChange={(event: any) => handleSelect('year', event)}
                />
              </Grid.Item>
            </Grid>
          </div>
          <Button
            size="xl"
            name="exportReport"
            color="secondary"
            className={styles.showReportButton}
            iconRight={{ name: 'download', font: 'hedemora' }}
            onClick={exportReport}
            disabled={!month || !year || exportLoading}
            loading={loading || exportLoading}
          >
            <FormattedMessage {...texts.downloadReport} />
            {month?.label} {year?.label}
          </Button>
        </div>
        <div className={styles.tableWrapper}>
          <Table
            columns={columns}
            data={messagesData?.messages.edges || []}
            onFetchData={fetchData}
            loading={messagesLoading || userLoading}
            truncate
            sort
            pagination
            totalPages={table.getTotalPages(messagesData?.messages.meta)}
            empty={<Empty title={<FormattedMessage {...texts.emptyTable} />} />}
          />
        </div>
        <div className={styles.closeButton}>
          <Button
            name="close"
            color="secondary"
            size="xl"
            onClick={() => setIsOpen(false)}
          >
            <FormattedMessage {...texts.close} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
