import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  seoTitle: {
    id: 'CustomersHeader.seoTitle',
    description: 'Seo title',
    defaultMessage: 'Kontakter'
  },
  customers: {
    id: 'CustomersHeader.customers',
    description: 'Cutomers heading',
    defaultMessage: 'Alla kunder'
  }
});
