import { ReactNode, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AdminHeader } from 'components/ui/backoffice';
import { ClientHeader } from 'components/ui/client';
import { GdprConsent } from 'components/ui/modals';
import { Consents } from 'consts/gdpr';
import { useTabAccess, useAuth } from 'hooks';
import { selectConsent } from 'redux/gdpr';
import { UserRole } from 'types/graphql';
import { sentry } from 'utils';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  useTabAccess();
  const { user, loggedIn } = useAuth();
  const consent = useSelector(selectConsent);
  const { pathname } = useLocation();

  useEffect(() => {
    if (consent.includes(Consents.All)) {
      sentry.setUser(user ? { email: user.email } : null);
    }
  }, [user, consent]);

  const renderHeader = () => {
    if (!loggedIn) return;
    if (pathname.includes('/backoffice') && user?.role === UserRole.Admin)
      return <AdminHeader />;
    return <ClientHeader />;
  };

  return (
    <>
      <div className={styles.root}>
        {renderHeader()}
        <div className={styles.content}>{children}</div>
      </div>
      <GdprConsent />
    </>
  );
};
