import { Container } from 'components/ui/general';

import styles from './Error.module.scss';

export const Error = () => {
  return (
    <div className={styles.root}>
      <Container>
        {'Router error, insert design for it...'.toString()}
      </Container>
    </div>
  );
};
