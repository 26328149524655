import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { Router, SEO } from 'components/tools';
import { setUser } from 'redux/auth';
import { useMeQuery, User } from 'types/graphql';

export const App = () => {
  const dispatch = useDispatch();
  const { data } = useMeQuery();

  useEffect(() => {
    const user = data?.me as User;
    if (user) dispatch(setUser({ user }));
  }, [dispatch, data]);

  return (
    <>
      <SEO titleTemplate="%s | StellarTech" defaultTitle="StellarTech" />
      <Router />
    </>
  );
};
