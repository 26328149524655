import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { LargeConfirmIcon, LargeInfoIcon } from 'assets/icons';
import { Button } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Keys } from 'consts/keys';
import { useKeyPress } from 'hooks';
import { Contact, ContactList } from 'types/graphql';

import { texts } from './SendMessageModal.text';

import styles from './SendMessageModal.module.scss';

interface SendMessageModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsMessageSent: Dispatch<SetStateAction<boolean>>;
  isMessageSent: boolean;
  handleSendMessage: any;
  receivers: Contact | ContactList;
  reset: () => void;
  disabled: boolean;
}

export const SendMessageModal = ({
  setIsOpen,
  setIsMessageSent,
  isMessageSent,
  handleSendMessage,
  receivers,
  reset,
  disabled
}: SendMessageModalProps) => {
  const closeModals = () => {
    setIsMessageSent(false);
    setIsOpen(false);
  };

  const [receiverNameOrCount, setReceiverNameOrCount] = useState('');

  useKeyPress(Keys.ESC, () => {
    setIsMessageSent(false);
    setIsOpen(false);
  });

  useEffect(() => {
    if (receivers?.__typename === 'Contact') {
      setReceiverNameOrCount(`${receivers?.firstName} ${receivers?.lastName}`);
    }
    if (receivers?.__typename === 'ContactList') {
      setReceiverNameOrCount(`${receivers.contactCount} mottagare`);
    }
  }, [receivers]);

  return (
    <Modal isOpen onClose={() => setIsMessageSent(false)}>
      <div className={styles.main}>
        {!isMessageSent && (
          <>
            <div>
              <LargeInfoIcon className={styles.infoIcon} />
            </div>
            <h5 className={styles.heading}>
              <FormattedMessage {...texts.heading} />
            </h5>
            <div className={styles.body}>
              <FormattedMessage {...texts.body} />
              {receiverNameOrCount}
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.cancelButton}
                color="secondary"
                size="xl"
                onClick={() => setIsOpen(false)}
              >
                <FormattedMessage {...texts.cancel} />
              </Button>
              <Button
                size="xl"
                onClick={() => handleSendMessage()}
                disabled={disabled}
                loading={disabled}
              >
                <FormattedMessage {...texts.confirm} />
              </Button>
            </div>
          </>
        )}
        {isMessageSent && (
          <>
            <div>
              <LargeConfirmIcon className={styles.infoIcon} />
            </div>
            <h5 className={styles.heading}>
              <FormattedMessage {...texts.successHeading} />
            </h5>
            <div className={styles.body}>
              <FormattedMessage {...texts.successBody} />
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                size="xl"
                onClick={() => {
                  reset();
                  closeModals();
                }}
              >
                <FormattedMessage {...texts.backToMessages} />
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
