import {
  useMemo,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect
} from 'react';

import union from 'lodash/union';
import { useForm } from 'react-hook-form';

import { Select, SelectProps } from 'components/ui/forms';
import { QueryParameters } from 'consts/table';
import { useUrlQuery } from 'hooks';

export interface FilterSelectProps {
  select: {
    name: SelectProps['name'];
    multiLabelWithNumber: SelectProps['multiLabelWithNumber'];
    loading?: SelectProps['loading'];
    options: {
      value: string;
      label: string;
    }[];
  };
  setActiveFilter: Dispatch<SetStateAction<string[]>>;
  useQueryParameters: boolean;
}

export const FilterSelect = ({
  select,
  setActiveFilter,
  useQueryParameters
}: FilterSelectProps) => {
  const { control, setValue } = useForm();
  const { getAll, append, hasNameWithValue, deleteNameWithValue } =
    useUrlQuery();
  const defaultValue = useMemo(() => {
    if (useQueryParameters) {
      const collectOptions: FilterSelectProps['select']['options'] = [];
      getAll(QueryParameters.Filter)?.forEach((filter: string) => {
        const findFilter = select.options.find(({ value }) => value === filter);
        if (findFilter) collectOptions.push(findFilter);
      });

      return collectOptions;
    }
  }, [select.options, getAll, useQueryParameters]);

  const onChange = useCallback(
    (data: FilterSelectProps['select']['options']) => {
      const action = 'replace';

      setActiveFilter((prevActiveFilter) => {
        let updateActiveFilter = prevActiveFilter;

        select.options.forEach(({ value }) => {
          const findOption = data.find((hold) => hold.value === value);

          if (findOption) {
            updateActiveFilter = union(updateActiveFilter, [value]);

            if (
              !hasNameWithValue(QueryParameters.Filter, findOption.value) &&
              useQueryParameters
            ) {
              append(QueryParameters.Filter, findOption.value, action);
            }
          } else {
            updateActiveFilter = updateActiveFilter.filter(
              (activeFilter) => activeFilter !== value
            );

            if (useQueryParameters) {
              deleteNameWithValue(QueryParameters.Filter, value, action);
            }
          }
        });

        return updateActiveFilter;
      });
    },
    [
      select.options,
      hasNameWithValue,
      append,
      deleteNameWithValue,
      setActiveFilter,
      useQueryParameters
    ]
  );

  useEffect(() => {
    setValue(select.name, defaultValue);
  }, [defaultValue, select.name, setValue]);

  return (
    <Select
      name={select.name}
      multiLabelWithNumber={select.multiLabelWithNumber}
      placeholder={select.multiLabelWithNumber}
      loading={select.loading}
      options={select.options}
      control={control}
      fullWidth
      isClearable={false}
      isSearchable={false}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={(data) =>
        onChange(data as FilterSelectProps['select']['options'])
      }
      defaultValue={defaultValue}
    />
  );
};
