import { ComponentProps } from 'react';

import { FormattedMessage } from 'react-intl';
import { components } from 'react-select';

import { texts } from '../Select.text';

export const NoOptionsMessage = (
  props: ComponentProps<typeof components.NoOptionsMessage>
) => {
  return (
    <components.NoOptionsMessage {...props}>
      {props.children === 'No options' ? (
        <FormattedMessage {...texts.noOptionsMessage} />
      ) : (
        props.children
      )}
    </components.NoOptionsMessage>
  );
};
