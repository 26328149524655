import { defineMessages } from 'react-intl';

export const texts = defineMessages({
  importContacts: {
    id: 'ImportContactsModal.importContacts',
    description: 'Table header title',
    defaultMessage: 'Importera kontakter'
  },
  uploadCsv: {
    id: 'ImportContactsModal.uploadCsv',
    description: 'Upload CSV text',
    defaultMessage: 'Ladda upp CSV-fil'
  },
  buttonLabel: {
    id: 'ImportContactsModal.buttonLabel',
    description: 'Upload CSV button label',
    defaultMessage: 'Välj fil'
  },
  cancel: {
    id: 'ImportContactsModal.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Avbryt'
  },
  uploadFile: {
    id: 'ImportContactsModal.uploadFile',
    description: 'Import contacts button label',
    defaultMessage: 'Ladda upp fil'
  },
  uploadError: {
    id: 'ImportContactsModal.uploadError',
    description: 'Import contacts error message',
    defaultMessage: 'Hoppsan! Något gick fel. Försök igen.'
  },
  uploadSuccess: {
    id: 'ImportContactsModal.uploadSuccess',
    description: 'Import contacts success message',
    defaultMessage: 'Kontakterna har importerats'
  },
  importRules: {
    id: 'ImportContactsModal.importRules',
    description: 'Rules for contact import',
    defaultMessage:
      "Tänk på att din importfil måste innehålla rubriker till varje kolumn, samt att telefonnummer har formatet +46701112233.\n\nOm du behöver en exempelfil så finns en mall att ladda ner via knappen 'Ladda ner kontaktmall'."
  },
  chooseContactList: {
    id: 'ImportContactsModal.chooseContactList',
    description: 'Label for pick contact list dropdown',
    defaultMessage: 'Lägg till i lista (valfritt)'
  },
  chooseContactListError: {
    id: 'ImportContactsModal.chooseContactListError',
    description: 'Label for pick contact list dropdown',
    defaultMessage: 'Kunde inte hämta listor'
  }
});
