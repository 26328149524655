import { ComponentProps } from 'react';

import { components } from 'react-select';

import styles from '../Select.module.scss';

export const MultiValue = (
  props: ComponentProps<typeof components.MultiValue>
) => {
  const { index, getValue } = props;
  const { selectProps }: { [key: string]: any } = props;
  const { multiLabelWithNumber } = selectProps;

  if (multiLabelWithNumber) {
    if (index === 0) {
      return (
        <div className={styles.multiLabelWithNumber}>
          <div className={styles.multiLabelWithNumberLabel}>
            {multiLabelWithNumber}
          </div>
          <div className={styles.multiLabelWithNumberNumber}>
            {getValue().length}
          </div>
        </div>
      );
    }

    return null;
  }

  return <components.MultiValue {...props} />;
};
