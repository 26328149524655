import {
  useCallback,
  useEffect,
  useMemo,
  ClassAttributes,
  HTMLAttributes,
  ReactNode,
  useRef,
  useState,
  isValidElement
} from 'react';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTable, useSortBy, usePagination } from 'react-table';

import { Grid, Icon, Spinner } from 'components/ui/general';
import {
  Pagination,
  Search,
  FilterSelect,
  FilterSelectProps
} from 'components/ui/table';
import { TableSelectors } from 'consts/cypress';
import { QueryParameters } from 'consts/table';
import { useUrlQuery } from 'hooks';

import styles from './Table.module.scss';

export interface TableFetchData {
  pageIndex: number;
  pageSize: number;
  sortBy: {
    id: string;
    desc: boolean;
  }[];
  searchTerm: string;
  activeFilter?: string[];
}

export interface TableProps {
  columns: any[];
  data: any[];
  onFetchData: ({
    pageIndex,
    pageSize,
    sortBy,
    searchTerm
  }: TableFetchData) => void;
  maxWidth?: boolean;
  pageSizeSteps?: number[];
  totalPages?: number;
  className?: string;
  classNameTable?: string;
  classNameSearch?: string;
  classNamePagination?: string;
  truncate?: boolean;
  pagination?: boolean;
  sort?: boolean;
  initialSortCreatedAt?: boolean;
  empty?: ReactNode;
  loading?: boolean;
  search?: boolean;
  useQueryParameters?: boolean;
  filter?: {
    select: FilterSelectProps['select'];
  }[];
  lastTdRight?: boolean;
  searchTermProp?: string;
}

export const Table = ({
  columns,
  data,
  onFetchData,
  maxWidth,
  totalPages,
  pageSizeSteps = [10, 20, 30, 40, 50],
  className,
  classNameTable,
  classNameSearch,
  classNamePagination,
  truncate,
  pagination,
  sort,
  initialSortCreatedAt,
  empty,
  loading,
  search,
  useQueryParameters = true,
  filter,
  lastTdRight,
  searchTermProp
}: TableProps) => {
  const { get, getAll, setAll, deleteAll } = useUrlQuery();
  const initalSortBy = useMemo(() => {
    if (initialSortCreatedAt) {
      return [
        {
          id: 'createdAt',
          desc: true
        }
      ];
    }
    if (!useQueryParameters) {
      return [];
    }

    const id = get(QueryParameters.SortById);
    const desc = get(QueryParameters.SortByDesc);

    return id && desc
      ? [
          {
            id,
            desc: desc === 'true'
          }
        ]
      : [];
  }, [get, initialSortCreatedAt, useQueryParameters]);
  const { register, watch } = useForm();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: useQueryParameters
          ? Number(get(QueryParameters.PageIndex))
          : 0,
        pageSize: pageSizeSteps[0],
        sortBy: initalSortBy
      },
      manualPagination: !!pagination,
      manualSortBy: !!sort,
      pageCount: totalPages
    },
    !!sort && useSortBy,
    !!pagination && usePagination
  );
  const initiated = useRef(false);
  const [searchTerm, setSearchTerm] = useState<string>(
    useQueryParameters ? get(QueryParameters.SearchTerm) || '' : ''
  );
  const [activeFilter, setActiveFilter] = useState<string[]>(
    useQueryParameters ? getAll(QueryParameters.Filter) || [] : []
  );

  useEffect(() => {
    if (pageIndex + 1 > pageCount) {
      gotoPage(0);
    }
  }, [gotoPage, pageCount, pageIndex]);

  useEffect(() => {
    if (!useQueryParameters) {
      return;
    }

    const action = 'replace';
    let deleteItems: string[] = [];
    let setItems = [
      {
        name: QueryParameters.PageIndex,
        value: pageIndex
      }
    ];

    // Sort by
    if (sortBy?.length) {
      const { id, desc } = sortBy[0];
      setItems = [
        ...setItems,
        {
          name: QueryParameters.SortById,
          value: id
        },
        {
          name: QueryParameters.SortByDesc,
          value: desc
        }
      ];
    } else {
      deleteItems = [
        ...deleteItems,
        QueryParameters.SortById,
        QueryParameters.SortByDesc
      ];
    }

    // Search term
    if (searchTerm.length || searchTermProp?.length) {
      setItems = [
        ...setItems,
        {
          name: QueryParameters.SearchTerm,
          value: searchTerm || searchTermProp
        }
      ];
    } else {
      deleteItems = [...deleteItems, QueryParameters.SearchTerm];
    }

    setAll(setItems, action);
    if (deleteItems.length) deleteAll(deleteItems, action);
  }, [
    pageIndex,
    sortBy,
    searchTerm,
    useQueryParameters,
    setAll,
    deleteAll,
    searchTermProp
  ]);

  useEffect(() => {
    onFetchData({
      pageIndex,
      pageSize,
      sortBy: sortBy || [],
      searchTerm,
      activeFilter
    });
    initiated.current = true;
  }, [onFetchData, pageIndex, pageSize, sortBy, searchTerm, activeFilter]);

  const getRows = useMemo(
    () => (pagination ? page : rows),
    [page, pagination, rows]
  );

  const renderHead = useCallback(
    () =>
      headerGroups.map(
        (headerGroup: {
          getHeaderGroupProps: () => JSX.IntrinsicAttributes &
            ClassAttributes<HTMLTableRowElement> &
            HTMLAttributes<HTMLTableRowElement>;
          headers: any[];
        }) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className={styles.tr}
            data-cy={TableSelectors.HeadTr}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps?.())}
                className={classNames(styles.th, column.classNameTh)}
                data-cy={TableSelectors.HeadTh}
              >
                <div className={styles.thText}>
                  {column.render('Header')}
                  {sort &&
                    !column.disableSortBy &&
                    (typeof column.Header === 'string' ||
                      isValidElement(column.Header)) && (
                      <Icon
                        name={column.isSortedDesc ? 'caret-up' : 'caret-down'}
                        className={classNames(styles.thIcon, {
                          [styles.isSorted]: column.isSorted
                        })}
                      />
                    )}
                </div>
              </th>
            ))}
          </tr>
        )
      ),
    [headerGroups, sort]
  );

  const renderBody = useCallback(() => {
    if (!loading) {
      return getRows.map(
        (row: {
          getRowProps: () => JSX.IntrinsicAttributes &
            ClassAttributes<HTMLTableRowElement> &
            HTMLAttributes<HTMLTableRowElement>;
          cells: any[];
        }) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={styles.tr}
              data-cy={TableSelectors.BodyTr}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={classNames(styles.td, cell.column.classNameTd, {
                      [styles.tdWrap]: !!cell.column.wrapTd
                    })}
                    data-cy={TableSelectors.BodyTd}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        }
      );
    }

    return null;
  }, [getRows, loading, prepareRow]);

  const renderTop = useCallback(() => {
    if (search || filter) {
      return (
        <div className={styles.top}>
          <Grid gutter={{ left: 2, bottom: 3 }} align="middle">
            {!!search && (
              <Grid.Item width={3}>
                <Search
                  register={register}
                  watch={watch}
                  classNameSearch={classNameSearch}
                  onWatch={(value) => setSearchTerm(value)}
                  defaultValue={searchTerm}
                />
              </Grid.Item>
            )}
            {!!filter &&
              filter.map(({ select }) => (
                <Grid.Item width={3} key={select.name}>
                  <FilterSelect
                    select={select}
                    setActiveFilter={setActiveFilter}
                    useQueryParameters={useQueryParameters}
                  />
                </Grid.Item>
              ))}
          </Grid>
        </div>
      );
    }

    return null;
  }, [
    search,
    filter,
    register,
    watch,
    classNameSearch,
    searchTerm,
    useQueryParameters
  ]);

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.maxWidth]: maxWidth,
        [styles.truncate]: truncate,
        [styles.lastTdRight]: lastTdRight
      })}
      data-cy={TableSelectors.Root}
    >
      {renderTop()}
      <div>
        <div className={classNames(styles.tableHolder, classNameTable)}>
          <table {...getTableProps()} className={styles.table}>
            <thead data-cy={TableSelectors.Head}>{renderHead()}</thead>
            <tbody {...getTableBodyProps()} data-cy={TableSelectors.Body}>
              {renderBody()}
            </tbody>
          </table>
        </div>
        {!!loading && (
          <div className={styles.spinnerHolder}>
            <Spinner visible />
          </div>
        )}
        {!loading && !getRows.length && initiated.current && (
          <div className={styles.emptyHolder}>{empty}</div>
        )}
        {!loading && !!getRows.length && pagination && (
          <Pagination
            classNamePagination={classNamePagination}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={totalPages || pageCount}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
          />
        )}
      </div>
    </div>
  );
};
