import {
  ChangeEvent,
  useCallback,
  useMemo,
  MutableRefObject,
  ReactNode,
  MouseEventHandler,
  useState
} from 'react';

import classNames from 'classnames';
import { RegisterOptions, UseFormRegister, FieldErrors } from 'react-hook-form';

import { Error } from 'components/ui/forms';
import { Icon, IconProps, Information } from 'components/ui/general';
import { TextSelectors } from 'consts/cypress';
import { Fonts } from 'types/icon';

import styles from './LabeledText.module.scss';

type IconPropsExtended = IconProps & {
  onClick?: MouseEventHandler;
};

export interface LabeledTextProps {
  name: string;
  type?: string;
  placeholder?: string;
  ariaLabel?: string;
  label: ReactNode;

  className?: string;
  color?: 'light'; // color names $all-colors
  disabled?: boolean;
  register: UseFormRegister<any>;
  validation?: RegisterOptions;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  error?: FieldErrors;
  iconRight?: IconPropsExtended;
  iconLeft?: IconPropsExtended;
  rounded?: boolean;
  defaultValue?: string;
  information?: ReactNode;
  success?: boolean;
  readOnly?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  textRight?: ReactNode;
  textLeft?: ReactNode;
  maxLength?: number;
}

export const LabeledText = ({
  name,
  type = 'text',
  placeholder,
  ariaLabel,
  label,
  color = 'light',
  className,
  disabled,
  register,
  validation = {},
  onChange,
  onBlur,
  fullWidth,
  error,
  iconRight,
  iconLeft,
  rounded,
  defaultValue,
  information,
  success,
  readOnly,
  inputRef,
  textRight,
  textLeft,
  maxLength
}: LabeledTextProps) => {
  const Root = useMemo(() => (label ? 'label' : 'div'), [label]);
  const [showLabel, setShowLabel] = useState(false);
  const size = 'lg'; // Make this prop if needed in the future.

  const getIconFont = useCallback(({ font }: { font?: Fonts }) => {
    if (font) {
      return font;
    }

    return 'hedemora';
  }, []);

  const renderIcon = useCallback(
    (icon: IconPropsExtended) => {
      const { name: iconName, onClick } = icon;
      const IconButton = onClick ? 'button' : 'div';
      const disabledOrReadOnly = disabled || readOnly;

      return (
        <IconButton
          type={onClick ? 'button' : undefined}
          onClick={onClick && !disabledOrReadOnly ? onClick : undefined}
          className={onClick ? styles.iconButton : undefined}
        >
          <Icon name={iconName} font={getIconFont(icon)} />
        </IconButton>
      );
    },
    [disabled, getIconFont, readOnly]
  );

  const getValidation = useMemo(() => {
    return !disabled && !readOnly ? validation : {};
  }, [disabled, readOnly, validation]);

  const registerHolder = useMemo(
    () => register(name, getValidation),
    [name, register, getValidation]
  );

  const setShowLabelCallback = useCallback(
    (visible: boolean) => {
      if (showLabel === visible) return;
      setShowLabel(visible);
    },
    [showLabel]
  );

  return (
    <>
      <Root
        className={classNames(styles.root, className, {
          [styles.disabled]: disabled,
          [styles[`${color}Color`]]: color,
          [styles[`${size}Size`]]: size,
          [styles.fullWidth]: fullWidth,
          [styles.hasIconRight]: iconRight,
          [styles.hasIconLeft]: iconLeft,
          [styles.rounded]: rounded,
          [styles.error]: !!error,
          [styles.success]: success,
          [styles.readOnly]: readOnly,
          [styles.hasTextRight]: textRight,
          [styles.hasTextLeft]: textLeft
        })}
        data-cy={TextSelectors.Root}
      >
        {showLabel && (
          <div className={styles.label}>
            {label}
            {getValidation.required ? ' *' : null}
          </div>
        )}
        <div className={styles.inputHolder}>
          <input
            maxLength={maxLength}
            type={type}
            disabled={disabled}
            autoComplete="off"
            placeholder={`${placeholder}${getValidation.required ? ' *' : ''}`}
            aria-label={ariaLabel || placeholder}
            className={classNames([
              styles.input,
              { [styles.labeledPadding]: showLabel }
            ])}
            defaultValue={defaultValue}
            readOnly={readOnly}
            data-cy={TextSelectors.Input}
            {...registerHolder}
            ref={(event) => {
              if (inputRef) inputRef.current = event;
              registerHolder.ref(event);
            }}
            onChange={(event) => {
              setShowLabelCallback(!!event.target.value);
              registerHolder.onChange(event);
              onChange?.(event);
            }}
            onBlur={(event) => {
              registerHolder.onBlur(event);
              onBlur?.(event);
            }}
          />
          {!!iconLeft && (
            <div className={styles.iconLeft}>{renderIcon(iconLeft)}</div>
          )}
          {!!textLeft && <div className={styles.textLeft}>{textLeft}</div>}
          {!!iconRight && (
            <div className={styles.iconRight}>{renderIcon(iconRight)}</div>
          )}
          {!!textRight && <div className={styles.textRight}>{textRight}</div>}
        </div>
      </Root>
      {!!information && (
        <Information className={styles.informationText}>
          {information}
        </Information>
      )}
      <Error error={error} />
    </>
  );
};
