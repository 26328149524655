import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from 'components/ui/general';
import { Modal } from 'components/ui/modals';
import { Keys } from 'consts/keys';
import { useKeyPress, useToast } from 'hooks';
import {
  Scalars,
  useApproveUserMutation,
  useDeleteUserMutation,
  UsersDocument
} from 'types/graphql';

import { texts } from './ConfirmDenyUserModal.text';

import styles from './ConfirmDenyUserModal.module.scss';

interface SendMessageModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  userId: Scalars['ID'];
  mutationType: string;
}

export const ConfirmDenyUserModal = ({
  setIsOpen,
  userId,
  mutationType
}: SendMessageModalProps) => {
  useKeyPress(Keys.ESC, () => {
    setIsOpen(false);
  });

  const { formatMessage } = useIntl();

  const [approveUser, { loading: approveLoading, error: approveError }] =
    useApproveUserMutation();

  const [deleteUser, { loading: deleteLoading, error: deleteError }] =
    useDeleteUserMutation();

  const { addToast } = useToast();

  const runMutation = useCallback(() => {
    if (!mutationType) return;
    if (mutationType === 'accept') {
      approveUser({
        variables: {
          id: userId
        },
        refetchQueries: [UsersDocument]
      }).then((res) => {
        if (res.data?.approveUser) {
          addToast({
            title: formatMessage(texts.approveUserSuccess),
            type: 'success'
          });
        }
      });
    }
    if (mutationType === 'deny') {
      deleteUser({
        variables: {
          id: userId
        },
        refetchQueries: [UsersDocument]
      }).then((res) => {
        if (res.data?.deleteUser) {
          addToast({
            title: formatMessage(texts.deleteUserSuccess),
            type: 'success'
          });
        }
      });
    }
    setIsOpen(false);
  }, [
    addToast,
    approveUser,
    deleteUser,
    formatMessage,
    mutationType,
    setIsOpen,
    userId
  ]);

  useEffect(() => {
    if (approveError) {
      addToast({
        title: formatMessage(texts.approveError),
        type: 'error'
      });
    }
    if (deleteError) {
      addToast({
        title: formatMessage(texts.deleteError),
        type: 'error'
      });
    }
  }, [addToast, formatMessage, approveError, deleteError]);

  return (
    <Modal isOpen onClose={() => setIsOpen(false)}>
      <div className={styles.main}>
        <h5 className={styles.heading}>
          {mutationType === 'accept' ? (
            <FormattedMessage {...texts.confirmUser} />
          ) : (
            <FormattedMessage {...texts.deleteUser} />
          )}
        </h5>
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.cancelButton}
            color="secondary"
            size="xl"
            disabled={approveLoading || deleteLoading}
            onClick={() => setIsOpen(false)}
          >
            <FormattedMessage {...texts.cancel} />
          </Button>
          <Button
            color={mutationType === 'accept' ? 'success-light' : 'error-light'}
            size="xl"
            onClick={() => runMutation()}
          >
            {mutationType === 'accept' ? (
              <FormattedMessage {...texts.confirm} />
            ) : (
              <FormattedMessage {...texts.delete} />
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
