import * as Sentry from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Consents } from 'consts/gdpr';
import { isBuild, isStaging, isLocalhost, gdpr } from 'utils';

const okayToUseSentry = () =>
  isBuild() &&
  !isStaging() &&
  !isLocalhost() &&
  process.env.REACT_APP_SENTRY_DSN &&
  gdpr.getConsentValue().includes(Consents.Necessary);

const init = () => {
  if (okayToUseSentry()) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      integrations: [new Integrations.BrowserTracing() as any],
      tracesSampleRate: 1.0
    });
  }
};

const setUser = (user: Sentry.User | null) => {
  if (okayToUseSentry()) {
    Sentry.setUser(user);
  }
};

export const sentry = {
  init,
  setUser,
  ErrorBoundary: SentryReact.ErrorBoundary,
  withScope: Sentry.withScope,
  captureMessage: Sentry.captureMessage,
  Severity: Sentry.Severity
};
