import { isDemo, isStaging, isLocalhost } from 'utils';

interface ApiEnvironment {
  apiUrl?: string;
}

export const apiEnvironment: ApiEnvironment = {};

switch (true) {
  case !!process.env.REACT_APP_API_URL:
    apiEnvironment.apiUrl = 'https://api.stellartech.se/graphql';
    break;

  case isLocalhost():
    apiEnvironment.apiUrl = 'http://localhost:4000/graphql';
    break;

  case isDemo():
  case isStaging():
    apiEnvironment.apiUrl = 'https://api.stellartech.isdemo.se/graphql';
    break;

  default:
    apiEnvironment.apiUrl = 'https://api.stellartech.se/graphql';
}
